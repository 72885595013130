import React, { useState, useEffect } from "react";

import {
  Campaign,
  PromoCodeType,
  PromoCodeTypeLabels,
  RedeemTypeLabels
} from "admin/store/campaigns";
import { DateFormat, timeToFormat } from "shared/helpers/date";
import { Permissions } from "admin/helpers/permissions";
import { AuthState } from "shared/store/auth";

const CampaignDetails = ({
  campaign,
  auth,
  editAction
}: {
  campaign: Campaign;
  auth: AuthState;
  editAction: () => void;
}) => {
  const [permission, setPermission] = useState<Permissions>();
  useEffect(() => {
    if (auth.isLoaded) {
      setPermission(new Permissions(auth));
    }
  }, [auth]);

  const canEdit = () => {
    if (permission) {
      return permission.can("campaigns.edit");
    }
    return false;
  };

  return (
    <div className="section">
      <div className="content">
        <div className="data-group">
          <div className="label">Start date:</div>
          <div
            className="value"
            title={
              campaign.startOfCampaign
                ? new Date(campaign.startOfCampaign).toString()
                : "Not set"
            }
          >
            {campaign.startOfCampaign && (
              <>
                {timeToFormat(campaign.startOfCampaign, DateFormat.ShortDate)}
              </>
            )}
            {!campaign.startOfCampaign && <>/</>}
          </div>
        </div>
        <div className="data-group">
          <div className="label">End date:</div>
          <div
            className="value"
            title={
              campaign.endOfCampaign
                ? new Date(campaign.endOfCampaign).toString()
                : "Not set"
            }
          >
            {campaign.endOfCampaign && (
              <>{timeToFormat(campaign.endOfCampaign, DateFormat.ShortDate)}</>
            )}
            {!campaign.endOfCampaign && <>/</>}
          </div>
        </div>
        <div className="data-group">
          <div className="label">Type:</div>
          <div className="value">{PromoCodeTypeLabels[campaign.type]}</div>
        </div>
        <div className="data-group">
          <div className="label">Amount:</div>
          <div className="value">{campaign.numberOfCodes}</div>
        </div>
        <div className="data-group">
          <div className="label">Trial duration (days):</div>
          <div className="value">
            {campaign.promoDurationInDays ? campaign.promoDurationInDays : "/"}
          </div>
        </div>
        <div className="data-group">
          <div className="label">Redeem type:</div>
          <div className="value">
            {campaign.redeemType && campaign.redeemType in RedeemTypeLabels
              ? RedeemTypeLabels[campaign.redeemType]
              : campaign.redeemType || "/"}
          </div>
        </div>
        {campaign.type === PromoCodeType.GENERIC && campaign.uniqueCodes && (
          <div className="data-group">
            <div className="label">Promo code:</div>
            <div className="value">{campaign.uniqueCodes[0].promoCode}</div>
          </div>
        )}
      </div>
      <div className="actions">
        {canEdit() && (
          <button className="btn action filled" onClick={editAction}>
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default CampaignDetails;
