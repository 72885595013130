import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";

import { ApplicationState } from "admin/store";

import CampaignApi from "../../store/campaigns/api";
import {
  Campaign,
  CampaignUpdateParameters,
  PromoCodeType
} from "../../store/campaigns";

import { toast } from "react-toastify";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import CampaignDetails from "./sections/CampaignDetails";
import EditCampaignForm from "./forms/EditCampaignForm";
import UniqueCodes from "./sections/UniqueCodes";

import "./Campaign.scss";

const CampaignPage = ({
  match,
  history
}: RouteComponentProps<{ campaignUID: string }>) => {
  const auth = useSelector((state: ApplicationState) => state.auth);

  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [campaignUID, setCampaignUID] = useState<string>();
  useEffect(() => {
    if (match.params.campaignUID) {
      setCampaignUID(match.params.campaignUID);
    }
  }, [match]);

  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const redirect = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );
  useEffect(() => {
    const fetchData = async () => {
      if (campaignUID) {
        if (campaignUID === "create") {
          setEditMode(true);
        } else {
          setIsLoading(true);
          try {
            setCampaign(await CampaignApi.getCampaign(campaignUID));
          } catch (e) {
            toast.error(e.toString());
            redirect("/campaigns");
          } finally {
            setIsLoading(false);
          }
        }
      }
    };
    fetchData();
  }, [campaignUID, redirect]);

  const startEdit = () => {
    setEditMode(true);
  };
  const cancelEdit = () => {
    setEditMode(false);
  };
  const saveEdit = async (campaignData: CampaignUpdateParameters) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        if (campaign) {
          await CampaignApi.updateCampaign(campaignData);
          setCampaignUID(undefined);
          redirect(`/campaigns/${campaignUID}`);
        } else {
          const newCampaign = await CampaignApi.createCampaign(campaignData);
          redirect(`/campaigns/${newCampaign.campaignUID}`);
        }
        setEditMode(false);
      } catch (e) {
        toast.error(e.message);
        setIsLoading(false);
      }
    }
  };

  if (editMode) {
    return (
      <div className="app-body campaign-page">
        <div className="component-box">
          <h2 className="mt-0">
            {campaign ? `Campaign: ${campaign.campaignName}` : "New campaign"}
          </h2>
          <EditCampaignForm
            campaign={campaign}
            auth={auth}
            isLoading={isLoading}
            saveAction={saveEdit}
            cancelAction={cancelEdit}
          />
        </div>
      </div>
    );
  }
  if (!campaign) {
    return (
      <div className="app-body" title="Loading campaign...">
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <div className="app-body campaign-page">
      <div className="component-box with-header">
        <div className="component-box-header">
          <h2 className="mt-0">Campaign: {campaign.campaignName}</h2>
        </div>
        <CampaignDetails
          campaign={campaign}
          auth={auth}
          editAction={startEdit}
        />
      </div>

      {campaign.type === PromoCodeType.UNIQUE && (
        <UniqueCodes campaign={campaign} />
      )}
    </div>
  );
};

export default CampaignPage;
