import {
  UsersState,
  FullUserState,
  BasicUserState,
  UserNotes,
  AddUserNoteParameters,
  EditUserNoteParameters,
  UserNotesApiResponse,
} from "./types";

import Api from "admin/helpers/api";
import { ProfileUpdateParameters } from "shared/store/user/types";

export async function filterUsers(query: string): Promise<UsersState> {
  const data = await Api.get("/users", {
    query,
  });
  if (data.Payload) {
    const payload = data.Payload;
    return {
      query,
      users: JSON.parse(payload),
    };
  } else {
    return {
      query,
      users: data.map(parseBasicUserResponse),
    };
  }
}

function normalizeUsername(username: string) {
  return username.replace("+", "%2B");
}

function parseBasicUserResponse(response: any): BasicUserState {
  let data: BasicUserState;
  data = {
    UID: response.UserData.username,
    name: response.UserData.name,
    purchaseId: response.UserData.purchaseId,
    braintreeCustomerId: response.SubscriptionData.braintreeCustomerId,
    _promoCodeRedeemed: response.SubscriptionData.codeRedeemed,
  };
  return data;
}

function parseUserResponse(response: any): FullUserState {
  let data: FullUserState;
  data = {
    UserData: response.UserData,
    AdminData: {
      ...response.AdminData,
    },
  };
  if (response.SubscriptionData) {
    data.SubscriptionData = response.SubscriptionData;
  }
  if (response.DeviceList) {
    data.DeviceList = response.DeviceList;
  }
  return data;
}

export async function getUser(username: string): Promise<FullUserState> {
  const response = await Api.get(`/user?UID=${normalizeUsername(username)}`);
  return parseUserResponse(response);
}

export async function putUser(
  username: string,
  parameters: ProfileUpdateParameters
): Promise<FullUserState> {
  const response = await Api.put(`/user`, {
    UID: username,
    name: parameters.name,
    country: parameters.country,
    email: parameters.email,
    analyticsOptIn: parameters.analyticsOptIn,
    optOutOfSale: parameters.optOutOfSale,
    twilightUpdateOptIn: parameters.twilightUpdateOptIn,
    partnerUpdateOptIn: parameters.partnerUpdateOptIn,
  });

  return parseUserResponse(response);
}

export async function delUser(username: string): Promise<void> {
  await Api.post(`/user`, {
    UID: username,
    action: "deleteUser",
  });
  return;
}

export async function delSub(username: string): Promise<void> {
  await Api.post("/remove-user-subscription", {
    UID: username,
  });
  return;
}

export async function transferSub(
  sourceUID: string,
  destinationUID: string
): Promise<void> {
  await Api.post("/transfer-user-subscription", {
    sourceUID,
    destinationUID,
  });
  return;
}

export async function delDevice(username: string, deviceKey: string) {
  const response = await Api.post(`/user`, {
    UID: username,
    data: { deviceKey },
    action: "forgetUserDevice",
  });
  if (!response || response.code !== "admin-device-deleted") {
    // tslint:disable-next-line:no-console
    console.error(response);
    throw new Error("Could not remove device.");
  }
  return;
}

export async function confirmEmail(username: string) {
  await Api.post(`/user`, {
    UID: username,
    action: "confirmUser",
  });
  return;
}

export async function resetPassword(username: string) {
  await Api.post(`/user`, {
    UID: username,
    action: "resetPassword",
  });
  return;
}

export interface RefundData {
  refundData: {
    refundedTransactionId: string;
    refundAmount: string;
    refundTimeMillis: string;
  };
}

export async function cancelSubscription(
  username: string
): Promise<FullUserState> {
  return await Api.post(`/user`, {
    UID: username,
    action: "cancelBraintree",
  });
}

export async function refundSubscription(
  username: string
): Promise<FullUserState & RefundData> {
  return await Api.post(`/user`, {
    UID: username,
    action: "refundBraintree",
  });
}

export async function getUserNotes(username: string): Promise<UserNotes> {
  return await Api.get(`/user/notes/${username}`);
}

export async function addNewUserNote(
  username: string,
  parameters: AddUserNoteParameters
): Promise<UserNotes> {
  const response = (await Api.post(`/user/notes/${username}`, {
    title: parameters.title,
    comment: parameters.comment,
    priority: parameters.priority,
  })) as UserNotesApiResponse;
  return response.notes;
}

export async function updateUserNote(
  username: string,
  noteId: string,
  parameters: EditUserNoteParameters
): Promise<UserNotes> {
  const response = (await Api.put(`/user/notes/${username}`, {
    noteId,
    comment: parameters.comment,
    state: parameters.state,
    priority: parameters.priority,
  })) as UserNotesApiResponse;
  return response.notes;
}

export async function deleteUserNote(
  username: string,
  noteId: string
): Promise<UserNotes> {
  const response = (await Api.del(
    `/user/notes/${username}/${noteId}`
  )) as UserNotesApiResponse;
  return response.notes;
}

export default {
  filterUsers,
  getUser,
  putUser,
  delDevice,
  delUser,
  delSub,
  transferSub,
  confirmEmail,
  resetPassword,
  refundSubscription,
  cancelSubscription,
  getUserNotes,
  addNewUserNote,
  updateUserNote,
  deleteUserNote,
};
