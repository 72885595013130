// tslint:disable:max-classes-per-file

import { toast } from "react-toastify";
import {
  EMAIL_OR_PASSWORD_INCORRECT,
  EMAIL_VERIFICATION_RESENT,
  ENTER_VALID_EMAIL,
  ENTER_VALID_PASSWORD,
  NO_ACCOUNT_MATCHES_DETAILS,
  PASSWORD_RESET_LINK_EXPIRED,
  PASSWORD_WAS_RESET_FOR_SECURITY,
  PASSWORD_WAS_SET,
} from "../../helpers/messages";
import { IApiSuccess } from "../../helpers/errors";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import * as Yup from "yup";
import * as Sentry from "@sentry/browser";
import { AuthState, AuthUser } from "./types";
import axios from "axios";

export class AuthMissing extends Error {
  constructor() {
    super("Not authenticated");
  }
}

export class AuthError extends Error {
  constructor(message?: string) {
    super(message || "Authentication Error");
  }
}

const config = {
  mandatorySignIn: true,
  region: "eu-west-1",
  userPoolId:
    process.env.REACT_APP_POOL_ID ||
    (process.env.ENTRY_POINT === "admin"
      ? process.env.REACT_APP_ADMIN_POOL_ID
      : process.env.REACT_APP_CLIENTS_POOL_ID),
  userPoolWebClientId:
    process.env.REACT_APP_POOL_CLIENT_ID ||
    (process.env.ENTRY_POINT === "admin"
      ? process.env.REACT_APP_ADMIN_POOL_CLIENT_ID
      : process.env.REACT_APP_CLIENTS_POOL_CLIENT_ID),
};

Auth.configure(config);

export async function getCognitoUser(): Promise<CognitoUser> {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (cognitoUser.signInUserSession) {
      axios.defaults.headers.common.Authorization =
        cognitoUser.signInUserSession.idToken.jwtToken;
    }
    return cognitoUser;
  } catch (e) {
    if (e === "not authenticated") {
      throw new AuthMissing();
    }
    throw e;
  }
}

export interface IAuthLogin {
  password: string;
  username: string;
}

export class NotAuthorized extends Error {}

export class PasswordResetRequired extends Error {
  constructor() {
    super(PASSWORD_WAS_RESET_FOR_SECURITY);
  }
}

export class UserDoesNotExist extends Error {
  constructor() {
    super(NO_ACCOUNT_MATCHES_DETAILS);
  }
}

export class NewPasswordRequired extends Error {
  public code: string;
  public cognitoUser: CognitoUser;
  constructor(cognitoUser: CognitoUser) {
    super("A password change is required.");
    this.code = "NewPasswordRequired";
    this.cognitoUser = cognitoUser;
  }
}

export async function loginCognitoUser(
  loginData: IAuthLogin
): Promise<CognitoUser> {
  try {
    const cognitoUser = await Auth.signIn(
      loginData.username,
      loginData.password
    );

    if (cognitoUser.signInUserSession) {
      axios.defaults.headers.common.Authorization =
        cognitoUser.signInUserSession.idToken.jwtToken;
    }

    if (
      cognitoUser.challengeName &&
      cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED"
    ) {
      throw new NewPasswordRequired(cognitoUser);
    }

    return cognitoUser;
  } catch (e) {
    switch (e.code) {
      case "UserNotFoundException":
      case "UserDoesNotExist":
        throw new UserDoesNotExist();
      case "NotAuthorizedException":
        // there could be multiple things here, not nothing critical
        // - Incorrect username or password.
        if (e.message === "Incorrect username or password.") {
          throw new NotAuthorized(EMAIL_OR_PASSWORD_INCORRECT);
        }
        break;
      case "PasswordResetRequiredException":
        throw new PasswordResetRequired();
      case "NewPasswordRequired":
        // our custom exception
        throw e;
    }

    Sentry.captureException(e);

    throw e;
  }
}

export const Schema = Yup.object().shape({
  username: Yup.string()
    .min(2, ENTER_VALID_EMAIL)
    .required(ENTER_VALID_EMAIL)
    .matches(/^[^\s]+$/, ENTER_VALID_EMAIL),
  password: Yup.string()
    .min(8, ENTER_VALID_PASSWORD)
    .required(ENTER_VALID_PASSWORD),
});

export async function logoutCognitoUser(): Promise<void> {
  // const Auth = await load();
  // cognito refreshes the page on success, and cant really fail
  return await Auth.signOut();
}

export async function cognitoUserToAuthState(cognitoUser: any) {
  // const Auth = await load();
  const attributes = await Auth.userAttributes(cognitoUser);
  const authState = {
    user: {
      username: cognitoUser.username,
    } as AuthUser,
    isLoggedIn: !!cognitoUser.signInUserSession,
  } as AuthState;

  for (const attr of attributes) {
    switch (attr.getName()) {
      case "sub":
        authState.user!.sub = attr.getValue();
        break;
      case "email":
        authState.user!.email = attr.getValue();
        break;
      case "firstName":
        authState.user!.name = attr.getValue();
        break;
      case "lastName":
        authState.user!.lastName = attr.getValue();
        break;
      case "locale":
        authState.user!.locale = attr.getValue();
        break;
    }
  }

  if (
    cognitoUser.signInUserSession &&
    cognitoUser.signInUserSession.accessToken &&
    cognitoUser.signInUserSession.accessToken.payload &&
    cognitoUser.signInUserSession.accessToken.payload["cognito:groups"]
  ) {
    authState.user!.groups =
      cognitoUser.signInUserSession.accessToken.payload["cognito:groups"];
  }

  return authState;
}

export async function completeNewPassword(
  unauthedCognitoUser: CognitoUser,
  password: string
) {
  // const Auth = await load();
  const cognitoUser = Auth.completeNewPassword(unauthedCognitoUser, password, {
    name: (unauthedCognitoUser as any).username,
    // we have multiple timezones and countries but just one locale
    locale: "en_GB",
  });

  if (cognitoUser) {
    return cognitoUser;
  }

  throw new AuthError(PASSWORD_WAS_SET);
}

export interface IAuthChangePassword {
  password: string;
}

export class ExpiredPasswordResetCode extends Error {
  constructor() {
    super(PASSWORD_RESET_LINK_EXPIRED);
  }
}

export async function signIn(username: string, password: string) {
  // const Auth = await load();
  return Auth.signIn(username, password);
}

export async function signOut() {
  // const Auth = await load();
  return Auth.signOut();
}

export async function userAttributes(cognitoUser: CognitoUser) {
  // const Auth = await load();
  return Auth.userAttributes(cognitoUser);
}

export async function currentAuthenticatedUser() {
  // const Auth = await load();
  return Auth.currentAuthenticatedUser();
}

export interface IForgotPasswordSubmit {
  user_name: string;
  confirmation_code: string;
  password: string;
}

export async function forgotPasswordSubmit(formData: IForgotPasswordSubmit) {
  // const Auth = await load();
  try {
    await Auth.forgotPasswordSubmit(
      formData.user_name,
      formData.confirmation_code,
      formData.password
    );
  } catch (e) {
    if (e.code === "ExpiredCodeException") {
      throw new ExpiredPasswordResetCode();
    }

    Sentry.captureException(e);
    throw e;
  }
}

export const setPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8).required(),
});

export async function resendConfirmationEmail(
  username: string
): Promise<IApiSuccess> {
  // const Auth = await load();
  if (!username) {
    throw new Error("Username not entered.");
  }

  return await Auth.resendSignUp(username)
    .then((details: any) => {
      // masked email of user: details.CodeDeliveryDetails.Destination
      toast.success(EMAIL_VERIFICATION_RESENT);

      return Promise.resolve(details);
    })
    .catch((e: any) => {
      toast.error(e.message || "Could not resend confirmation email.");
      throw e;
    });
}

export async function forgotPassword(email: string) {
  // const Auth = await load();
  return Auth.forgotPassword(email);
}
