import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import { NavLink } from "react-router-dom";
import { ApplicationState } from "../../store";
import { AuthState } from "shared/store/auth";
import * as authActions from "shared/store/auth/actions";

import { default as HeaderWrapper } from "shared/components/header/Header";
import { Permissions } from "../../helpers/permissions";

interface PropsFromState {
  auth: AuthState;
}

interface PropsFromDispatch {
  logOut: typeof authActions.authLogoutAction;
}

export const Header = ({
  auth,
  logOut,
}: PropsFromState & PropsFromDispatch) => {
  const canLogout = !!auth.user;
  const callLogout = logOut;
  const permissions = new Permissions(auth);

  const NavigationManu = () => {
    const showNavigation =
      auth.isLoggedIn &&
      permissions.can("campaigns.view") &&
      permissions.can("users.view");

    if (showNavigation) {
      return (
        <nav className="header-menu">
          <NavLink to="/campaigns" activeClassName="is-active">
            PROMO CODES
          </NavLink>{" "}
          |
          <NavLink to="/users" exact={true} activeClassName="is-active">
            USERS
          </NavLink>
        </nav>
      );
    }

    return null;
  };

  return (
    <>
      <HeaderWrapper>
        {canLogout && (
          <button className="btn" onClick={callLogout}>
            LOG OUT
          </button>
        )}
        {!canLogout && (
          <NavLink className="btn action" to="/login">
            LOGIN{" "}
          </NavLink>
        )}
      </HeaderWrapper>
      <NavigationManu />
    </>
  );
};

export default withRouter(
  connect<PropsFromState, PropsFromDispatch, any, ApplicationState>(
    ({ auth }: ApplicationState) => ({
      auth,
    }),
    (dispatch: Dispatch) => ({
      logOut: () => dispatch(authActions.authLogoutAction()),
    })
  )(Header)
);
