import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps } from "react-router";

import UsersApi from "../../store/users/api";

import { FullUserState } from "admin/store/users";
import { ProfileUpdateParameters } from "shared/store/user/types";

import { toast } from "react-toastify";

import Warnings from "./sections/Warnings";
import Info from "./sections/Info";
import Settings from "./sections/Settings";
import PrivacyAndNotificationsSection from "shared/components/users/PrivacyAndNotifications";
import SubscriptionSection from "./sections/Subscription";
import DevicesSection from "./sections/Devices";
import DangerousSection from "./sections/DangerousSection";
import UserNotesSection from "./sections/userNotes/UserNotes";
import Referrals from "./sections/referrals/Referrals";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";

import "shared/components/users/User.scss";

const User = ({
  match,
  history,
}: RouteComponentProps<{ username: string }>) => {
  const [username, setUsername] = useState<string>();
  useEffect(() => {
    if (match.params.username) {
      setUsername(match.params.username);
    }
  }, [match]);

  const fetchUser = useCallback(async () => {
    try {
      if (username) {
        setUser(await UsersApi.getUser(username));
      }
    } catch (e) {
      toast.error("Could not load user");
      history.replace("/users");
    }
  }, [username, history]);

  const [user, setUser] = useState<FullUserState>();
  useEffect(() => {
    fetchUser().then();
  }, [username, fetchUser]);

  const userDataUpdate = async (updateParameters: ProfileUpdateParameters) => {
    try {
      if (user && user.UserData) {
        const response = await UsersApi.putUser(user.UserData.username, {
          ...user.UserData,
          ...updateParameters,
        });
        setUser({ ...user, UserData: response.UserData });
      } else {
        toast.error("No user");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  if (!user || !user.UserData || !user.SubscriptionData || !user.AdminData) {
    return (
      <div className="app-body">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="app-body user-page">
      <Warnings user={user} />
      <Info adminData={user.AdminData!} />
      <Settings userData={user.UserData!} />
      <PrivacyAndNotificationsSection
        userData={user.UserData!}
        userDataUpdate={userDataUpdate}
      />
      <SubscriptionSection user={user} />
      <DevicesSection userData={user.UserData} devices={user.DeviceList} />
      <Referrals referralCodeData={user.AdminData.referralCode} />
      <DangerousSection user={user} />
      <UserNotesSection userData={user.UserData} />
    </div>
  );
};

export default User;
