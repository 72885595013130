export interface AuthState {
  readonly isLoaded?: boolean;
  readonly isLoggedIn: boolean;
  readonly targetLocation?: string;
  // even if this exists, the user might not be logged in
  //  since we re-use this object for temporary users
  //   that we gracefully allow to give us $$.
  readonly user?: AuthUser;
}

export interface AuthUser {
  name: string;
  lastName: string;
  /**
   * Cognito username
   */
  username: string;
  email: string;
  sub?: string;
  locale?: string;

  /**
   * Cognito groups, used for admin
   */
  groups: string[];
}

export enum AuthActionsTypes {
  AUTH_FETCH = "@@auth/AUTH_FETCH",
  AUTH_SET = "@@auth/AUTH_SET",
  AUTH_LOGOUT = "@@auth/AUTH_LOGOUT"
}
