import React from "react";
import * as Yup from "yup";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";

import {
  AddUserNoteParameters,
  UserNotePriorityMap,
  UserNotePriority,
  UserNoteState
} from "admin/store/users/types";

import FieldError from "shared/components/forms/elements/fieldError";
import FormButton from "shared/components/forms/elements/FormButton";

interface AddUserNoteFormProps {
  save: (params: AddUserNoteParameters) => Promise<any>;
}

const addUserNoteValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required()
    .required(),
  comment: Yup.string()
    .required()
    .required(),
  state: Yup.string(),
  priority: Yup.number()
});

const AddUserNoteFormTemplate = (
  props: FormikProps<AddUserNoteParameters> & AddUserNoteFormProps
) => {
  const { touched, errors, isSubmitting } = props;
  const loading = isSubmitting ? "loading" : "";

  return (
    <Form>
      <div className="form-group">
        {errors.title && touched.title && <FieldError message={errors.title} />}
        <Field
          id="title"
          name="title"
          type="text"
          className={
            errors.title && touched.title
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={128}
          placeholder="Title"
        />
        <div className="data-group mb-2">
          <div className="label">Priority</div>
          <div className="value">
            <Field component="select" name="priority" placeholder="Priority">
              {UserNotePriorityMap.map((value, i) => {
                return (
                  <option value={i} key={i}>
                    {value}
                  </option>
                );
              })}
            </Field>
            {errors.priority && touched.priority && (
              <div className="error-message">{errors.priority}</div>
            )}
          </div>
        </div>{" "}
        {errors.comment && touched.comment && (
          <FieldError message={errors.comment} />
        )}
        <Field
          id="comment"
          name="comment"
          type="text"
          className={
            errors.comment && touched.comment
              ? "form-control with-error"
              : "form-control"
          }
          placeholder="Comment"
        />
        <FormButton text="Save" loading={loading} />
      </div>
    </Form>
  );
};

const AddUserNoteForm = withFormik<AddUserNoteFormProps, AddUserNoteParameters>(
  {
    displayName: "AddUserNoteForm",
    handleSubmit: (
      values: AddUserNoteParameters,
      formikBag: FormikBag<AddUserNoteFormProps, AddUserNoteParameters>
    ) => {
      formikBag.props
        .save(values)
        .catch(e => {
          /**/
        })
        .finally(() => {
          formikBag.setSubmitting(false);
        });
    },
    mapPropsToValues: () => {
      return {
        title: "",
        comment: "",
        state: UserNoteState.OPEN,
        priority: UserNotePriority.MEDIUM
      };
    },
    validationSchema: addUserNoteValidationSchema
  }
)(AddUserNoteFormTemplate);

export default AddUserNoteForm;
