// tslint:disable:max-classes-per-file

interface IDetailedError {
  message: string;
  code: string;
  attribute: string;
}

export interface IApiError {
  code?: string;
  message?: string;
  reportId?: string;
  reportDSN?: string;
  errors?: IDetailedError[];
}

export interface IApiSuccess {
  code?: string;
  message?: string;
}

export class ApiError extends Error {
  public code?: string;
  public errors?: IDetailedError[];

  constructor(data: IApiError) {
    super(data.message);
    this.code = data.code;
    this.errors = data.errors;
  }
}

export class ValidationError extends ApiError {}

export interface IRedeemApiError extends IApiError {
  urlRedirect?: string;
}

export class CodeRedeemError extends ApiError {
  public urlRedirect?: URL;
  constructor(data: IRedeemApiError) {
    super(data);
    if (data.urlRedirect) {
      this.urlRedirect = new URL(data.urlRedirect);
    }
  }
}

export class ServerError extends ApiError {
  public reportId?: string;
  public reportDSN?: string;

  constructor(data: IApiError) {
    super(data);
    this.reportId = data.reportId;
    this.reportDSN = data.reportDSN;
  }
}
