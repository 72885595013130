import React, { useEffect, useState } from "react";

const DoubleSubscription = ({
  firstPurchaseId,
  secondPurchaseId
}: {
  firstPurchaseId: string;
  secondPurchaseId: string;
}) => {
  const [firstSubTitle, setFirstSubTitle] = useState("");
  useEffect(() => {
    // work out titles for each id
    setFirstSubTitle(titleFromPurchaseId(firstPurchaseId));
  }, [firstPurchaseId]);

  const [secondSubTitle, setSecondSubTitle] = useState("");
  useEffect(() => {
    setSecondSubTitle(titleFromPurchaseId(secondPurchaseId));
  }, [secondPurchaseId]);

  const titleFromPurchaseId = (purchaseId: string): string => {
    if (purchaseId.includes("GPA.")) {
      return "Google Subscription";
    } else if (purchaseId.length === 6) {
      return "Braintree Subscription";
    } else {
      return "Apple Subscription";
    }
  };

  return (
    <div className="section empty-section">
      <div className="content mb-2">
        <h1>This User has 2 Subscriptions!</h1>
        <p>
          {firstSubTitle}: {firstPurchaseId}
        </p>
        <p>
          {secondSubTitle}: {secondPurchaseId}
        </p>
      </div>
    </div>
  );
};

export default DoubleSubscription;
