import { CampaignActionsTypes, CampaignsState } from "./types";

export interface CampaignsSet {
  type: CampaignActionsTypes.CAMPAIGNS_SET;
  payload: Partial<CampaignsState>;
}

export interface CampaignsFilter {
  type: CampaignActionsTypes.CAMPAIGNS_FILTER;
  payload: string;
}

export function campaignsSetAction(campaigns: CampaignsState): CampaignsSet {
  return {
    type: CampaignActionsTypes.CAMPAIGNS_SET,
    payload: campaigns
  };
}

export function campaignsFilterAction(searchString: string): CampaignsFilter {
  return {
    payload: searchString,
    type: CampaignActionsTypes.CAMPAIGNS_FILTER
  };
}

export type CampaignActions = CampaignsSet | CampaignsFilter;
