import React from "react";
import "./LoadingIndicator.scss";

const LoadingIndicator = () => {
  return (
    <div className="loading-indicator">
      <div className="cloud">
        <svg width="140px" viewBox="-16 0 140 140" className="foreground">
          <g>
            <path
              fill="#FFFFFF"
              d="M107,66.7372881 C107,59.720339 103.971698,52.8983051 98.475891,47.6355932 C98.2515723,47.440678 98.139413,47.3432203 97.9150943,47.2457627 C93.0922432,42.8601695 86.8113208,39.9364407 79.9696017,39.059322 C76.2683438,33.6991525 70.548218,29.7033898 63.706499,27.559322 C63.706499,27.559322 63.706499,27.559322 63.706499,27.559322 C63.706499,27.559322 63.706499,27.559322 63.706499,27.559322 C63.706499,27.559322 63.706499,27.559322 63.5943396,27.559322 L63.5943396,27.559322 C60.5660377,26.5847458 57.3134172,26 53.6121593,26 C36.78826,26 22.9926625,37.6949153 22.4318658,52.2161017 C9.87002096,53.190678 0,62.4491525 0,73.6567797 C0,85.2542373 10.7672956,94.8050847 24.0020964,95 C24.0020964,95 24.1142558,95 24.1142558,95 L75.7075472,95 C75.8197065,95 75.9318658,95 76.0440252,95 C93.2044025,94.3177966 107,81.940678 107,66.7372881 Z"
              id="Cloud-solid"
            />
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0 "
              to="-15 -8 "
              end="0 0 "
              begin="0s;in.end"
              dur="0.5s"
              repeatCount="1"
              id="out"
            />
            <animateTransform
              attributeName="transform"
              type="translate"
              from="-15 -8 "
              to="0 0 "
              end="-155555 -8 "
              begin="out.end"
              dur="0.5s"
              repeatCount="1"
              id="in"
            />
          </g>
        </svg>
        <svg width="160px" className="background">
          <g>
            <path
              fill="#FFFFFF80"
              transform="translate(59, -10) scale(0.75, 0.75)"
              d="M107,66.7372881 C107,59.720339 103.971698,52.8983051 98.475891,47.6355932 C98.2515723,47.440678 98.139413,47.3432203 97.9150943,47.2457627 C93.0922432,42.8601695 86.8113208,39.9364407 79.9696017,39.059322 C76.2683438,33.6991525 70.548218,29.7033898 63.706499,27.559322 C63.706499,27.559322 63.706499,27.559322 63.706499,27.559322 C63.706499,27.559322 63.706499,27.559322 63.706499,27.559322 C63.706499,27.559322 63.706499,27.559322 63.5943396,27.559322 L63.5943396,27.559322 C60.5660377,26.5847458 57.3134172,26 53.6121593,26 C36.78826,26 22.9926625,37.6949153 22.4318658,52.2161017 C9.87002096,53.190678 0,62.4491525 0,73.6567797 C0,85.2542373 10.7672956,94.8050847 24.0020964,95 C24.0020964,95 24.1142558,95 24.1142558,95 L75.7075472,95 C75.8197065,95 75.9318658,95 76.0440252,95 C93.2044025,94.3177966 107,81.940678 107,66.7372881 Z"
              id="Cloud-solid"
            />
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0"
              to="17 8"
              end="0 0"
              begin="0s;in.end"
              dur="0.5s"
              repeatCount="1"
              id="out"
            />
            <animateTransform
              attributeName="transform"
              type="translate"
              from="17 8"
              to="0 0"
              end="17 8"
              begin="out.end"
              dur="0.5s"
              repeatCount="1"
              id="in"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default LoadingIndicator;
