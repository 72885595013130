import * as React from "react";
import "./SearchBar.scss";

interface SearchBarProps {
  input: (s: string) => any;
  value?: string;
}

interface SearchBarState {
  inputValue: string;
}

class SearchBar extends React.PureComponent<SearchBarProps, SearchBarState> {
  constructor(props: SearchBarProps) {
    super(props);
    this.state = {
      inputValue: props.value ? props.value : ""
    };
  }

  public componentDidUpdate(prevProps: Readonly<SearchBarProps>): void {
    if (this.props.value !== prevProps.value) {
      this.setState({
        inputValue: this.props.value || ""
      });
    }
  }

  public render() {
    const updateInputValue = (e: React.FormEvent<HTMLInputElement>) => {
      this.setState({ ...this.state, inputValue: e.currentTarget.value });
    };

    const searchClicked = () => {
      this.props.input(this.state.inputValue);
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        this.props.input(this.state.inputValue);
      }
    };

    return (
      <div className="search-bar-container">
        <input
          type="text"
          className="search-bar-input"
          value={this.state.inputValue}
          onChange={updateInputValue}
          onKeyPress={handleKeyPress}
        />
        <button className="btn purple search-button" onClick={searchClicked}>
          {" "}
          SEARCH{" "}
        </button>
      </div>
    );
  }
}

export default SearchBar;
