import React from "react";

interface GeneratedTableColumn {
  header: string;
  accessor: ((item: any) => string) | string;
}

function DataTable<T>({
  data,
  columns,
}: {
  data: Array<{ [_: string]: T }>;
  columns: GeneratedTableColumn[];
}) {
  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th>&nbsp;</th>
          {columns.map((x, i) => (
            <th key={i}>{x.header}</th>
          ))}
        </tr>
      </thead>
    );
  };

  const getTableBody = () => {
    return (
      <tbody>
        {data.map((item, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            {columns.map((column, j) => {
              if (typeof column.accessor === "string") {
                return <td key={i + "-" + j}>{item[column.accessor]}</td>;
              } else if (typeof column.accessor === "function") {
                return <td key={i + "-" + j}>{column.accessor(item)}</td>;
              } else {
                return <td />;
              }
            })}
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <table>
      {getTableHeader()}
      {getTableBody()}
    </table>
  );
}

export default DataTable;
