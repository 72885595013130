import * as React from "react";
import { ClientCognitoDevice } from "../../../store/user/types";
import { DateFormat, timeToFormat } from "../../../helpers/date";

const DevicesSection = ({
  devices,
  deleteDevice
}: {
  devices: ClientCognitoDevice[] | undefined;
  deleteDevice: (deviceKey: string) => void;
}) => {
  return (
    <div className="component-box">
      <div className="section devices-section with-loading-block">
        <div className="content">
          <h1 className="mt-0">Devices</h1>
          {(!devices || devices.length < 1) && (
            <div className="section devices-section empty-section">
              <h3>No registered devices found</h3>
            </div>
          )}
          {devices && devices.length > 0 && (
            <React.Fragment>
              {devices.map((device: ClientCognitoDevice) => (
                <DeviceRow
                  device={device}
                  key={device.DeviceKey}
                  deleteDeviceCallback={deleteDevice}
                />
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default DevicesSection;

const DeviceRow = ({
  device,
  deleteDeviceCallback
}: {
  device: ClientCognitoDevice;
  deleteDeviceCallback: (deviceKey: string) => void;
}) => {
  const deviceDelete = () => {
    deleteDeviceCallback(device.DeviceKey);
  };
  return (
    <div className="row">
      <div className="data-group">
        <div className="device">{device.DeviceName}</div>
        <label>
          Last login:{" "}
          {device.LastAuthenticatedDate
            ? timeToFormat(device.LastAuthenticatedDate, DateFormat.ShortDate)
            : "/"}
        </label>
      </div>
      <div className="right">
        <button
          type="button"
          className="btn secondary filled"
          onClick={deviceDelete}
        >
          Remove
        </button>
      </div>
    </div>
  );
};
