import {
  ClientDynamoUser,
  ClientSubscriptionData,
  SubscriptionPlatform,
} from "shared/store/user/types";

import { ClientCognitoDevice } from "shared/store/user/types";

export interface UsersState {
  readonly status?: string | null;
  readonly users?: BasicUserState[];
  readonly query?: string; // search query
  readonly isLoading?: boolean;
}

export interface BasicUserState {
  readonly UID: string;
  readonly name?: string;
  readonly purchaseId?: string;
  readonly braintreeCustomerId?: string;
  readonly _promoCodeRedeemed?: string;
  readonly _referralCode?: string;
}

export interface FullUserState {
  UserData?: ClientDynamoUser;
  SubscriptionData?: ClientSubscriptionData;
  DeviceList?: ClientCognitoDevice[];
  AdminData?: ClientAdminData;
}

export interface ClientAdminData {
  readonly createdAt: number;
  readonly userDeleted: boolean;
  readonly emailConfirmed: boolean;
  readonly accountConfirmed: boolean;
  readonly subscriptionHistory: SubscriptionHistory[];
  readonly purchaseId?: string;
  readonly createdPlatform: string;
  readonly trialCount: number;
  readonly didEmailDecline: boolean;
  readonly doubleSubscriptionData: DoubleSubscriptionData;
  readonly referralCode?: ReferralCodeData;
}

export interface SubscriptionHistory {
  readonly genericUID: string;
  readonly addedAt: number;
  readonly state: string;
  readonly platform: SubscriptionPlatform;
  readonly subscriptionId: string;
  readonly subscriptionType: string;
  readonly purchaseId: string;
  readonly paymentState: string;
  readonly subscriptionValid: boolean;
  readonly startTimeMillis: number;
  readonly renewalTimeMillis: number;
  readonly trialActive: boolean;
  readonly trialCount: number;
  readonly lastTrialEndTime: number;
  readonly isCancelled: boolean;
  readonly paidInitCount: number;
  readonly renewedCount: number;
  readonly expectedRenewalTime: number;
  readonly isHardDeclined: boolean;
  readonly isSoftDeclined: boolean;
  readonly isGrace: boolean;
  readonly isRefunded: boolean;
  readonly wasOnTrialBefore?: boolean;
  readonly renewingIntoSubscriptionId?: string;
  readonly subscriptionCurrency?: string;
  readonly subscriptionPrice?: number;
  [key: string]: undefined | boolean | number | string | SubscriptionPlatform;
}

export interface DoubleSubscriptionData {
  readonly UID: string;
  readonly mobilePurchaseId: string;
  readonly braintreePurchaseId: string;
}

export interface ReferralCodeData {
  code: string;
  amountRedeemed: number;
  redeemersUIDs: string[];
  referredByCode: string;
}

export const UserNotePriorityMap = ["Low", "Medium", "High"];
export enum UserNotePriority {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export enum UserNoteState {
  OPEN = "open",
  CLOSED = "closed",
  ESCALATED = "escalated",
}

export interface UserNotesApiResponse {
  uid: string;
  notes: UserNotes;
}

export interface UserNotes {
  readonly [noteId: string]: UserNote;
}

export interface UserNote {
  readonly addedAt: number;
  readonly title: string;
  readonly comments: UserComments[];
  readonly state: UserNoteState;
  readonly priority: UserNotePriority;
}

export interface UserComments {
  readonly addedAt: number;
  readonly comment: string;
}

export interface AddUserNoteParameters {
  title: string;
  comment: string;
  state?: UserNoteState;
  priority: UserNotePriority;
}

export interface EditUserNoteParameters {
  comment?: string;
  state?: UserNoteState;
  priority?: UserNotePriority;
}

export enum UsersActionsTypes {
  USERS_SET = "@@user/USERS_SET",
  USERS_FILTER = "@@user/USERS_FILTER",
}
