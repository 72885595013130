import { RedeemType } from "../../../shared/store/campaigns/types";

export const RedeemTypeLabels = {
  [RedeemType.FREE_ACCESS_CODE]: "Free Access Code",
  [RedeemType.PRODUCT_CODE]: "Product Code",
  [RedeemType.SCHOOL_CODE]: "School Code",
  [RedeemType.REFERRAL_CODE]: "Referral Code",
};

export enum PromoCodeType {
  GENERIC = "generic-campaign",
  UNIQUE = "unique-campaign",
}

export const PromoCodeTypeLabels = {
  [PromoCodeType.GENERIC]: "Free Generic",
  [PromoCodeType.UNIQUE]: "Free Unique",
};

export interface UniquePromoCode {
  promoCode: string;
  countOfRedeemed: number;
  [key: string]: number | string;
}

export interface Campaign {
  campaignName: string;
  campaignUID: string;
  startOfCampaign: string;
  createdAt: string;
  type: PromoCodeType;
  endOfCampaign: string;
  numberOfCodes: number;
  countOfRedeemed: number;
  promoDurationInDays: number;
  uniqueCodes: UniquePromoCode[];
  redeemType: RedeemType | null;
}

export interface CampaignUpdateParameters {
  promoCode?: string;
  campaignName: string;
  startOfCampaign: string;
  endOfCampaign: string;
  type: string;
  numberOfCodes: number;
  promoDurationInDays: number;
  redeemType: string;
}

export interface CampaignsState {
  readonly status?: string | null;
  readonly campaigns?: Campaign[];
  readonly isLoading?: boolean;
}

export enum CampaignActionsTypes {
  CAMPAIGNS_SET = "@@campaign/CAMPAIGNS_SET",
  CAMPAIGNS_FILTER = "@@campaign/CAMPAIGNS_FILTER",
}
