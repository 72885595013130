import Api from "admin/helpers/api"
import { Campaign, CampaignUpdateParameters } from "./types";

function parseCampaignResponse(response: any): Campaign {
  return { ...response, redeemType: response.redeemType || null };
}

/**
 * Right now the results aren't filtered but the code is ready to do it
 * @param query
 */
export async function filterCampaigns(query: string): Promise<Campaign[]> {
  return (await Api.get("/campaign")).map(parseCampaignResponse);
}

export async function getCampaign(promoCode: string): Promise<Campaign> {
  const response = await Api.get(`/campaign/${promoCode}`);
  return parseCampaignResponse(response);
}

export async function createCampaign(data: CampaignUpdateParameters) {
  const response = await Api.post("/campaign", data);
  return parseCampaignResponse(response);
}

export async function updateCampaign(data: CampaignUpdateParameters) {
  const response = await Api.put('/campaign', data);
  return parseCampaignResponse(response);
}

export default {
  getCampaign,
  filterCampaigns,
  updateCampaign,
  createCampaign
};
