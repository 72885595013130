import {
  applyMiddleware,
  createStore,
  compose,
  Store,
  Reducer,
  DeepPartial,
} from "redux";
import createSagaMiddleware from "redux-saga";
// `react-router-redux` is deprecated, so we use `connected-react-router`.
// This provides a Redux middleware which connects to our `react-router` instance.

export default function configureStore<ApplicationState>(
  initialState: DeepPartial<ApplicationState>,
  rootReducer: Reducer<ApplicationState>,
  rootSaga: any
): Store<ApplicationState> {
  // create the redux-saga middleware
  const composeEnhancers =
    ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const sagaMiddleware = createSagaMiddleware();
  const composed =
    process.env.REACT_APP_BRAINTREE_ENVIRONMENT === "production"
      ? applyMiddleware(sagaMiddleware)
      : composeEnhancers(applyMiddleware(sagaMiddleware));
  // We'll create our store with the combined reducers/sagas, and the initial Redux state that
  // we'll be passing from our entry point.
  const store = createStore(rootReducer, initialState, composed);

  // Don't forget to run the root saga, and return the store object.
  sagaMiddleware.run(rootSaga);

  return store;
}
