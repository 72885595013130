import { Campaign, CampaignActionsTypes as actions } from "./types";

import { call, put, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";
import CampaignsApi from "./api";
import { captureException } from "../../helpers/logger";
import { CampaignsFilter, campaignsSetAction } from "./actions";

function* filterCampaigns(action: CampaignsFilter) {
  yield put(campaignsSetAction({ isLoading: true }));
  try {
    const state : Campaign[] = yield call(CampaignsApi.filterCampaigns, action.payload);
    yield put(campaignsSetAction({ campaigns: state, isLoading: false, status: "" }));
  } catch (e) {
    yield call(captureException, e, {
      category: "admin.users.filterCampaigns"
    });
    yield call(toast.error, e.toString());
    yield put(campaignsSetAction({ isLoading: false, status: e.toString() }));
  }
}

export function* campaignsSagas() {
  yield takeLatest(actions.CAMPAIGNS_FILTER, filterCampaigns);
}
