import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";

import { UsersActionsTypes } from "./types";
import UsersApi from "./api";
import { UsersFilter, usersSetAction } from "./actions";
import { captureException } from "../../helpers/logger";

function* filterUsers(action: UsersFilter) {
  yield put(usersSetAction({ isLoading: true }));
  try {
    const state = yield call(UsersApi.filterUsers, action.payload);
    yield put(usersSetAction({ ...state, isLoading: false, status: "" }));
  } catch (e) {
    yield call(captureException, e, { category: "admin.users.filterUsers" });
    yield call(toast.error, e.toString());
    yield put(usersSetAction({ isLoading: false, status: e.toString() }));
  }
}

export function* usersSagas() {
  yield takeLatest(UsersActionsTypes.USERS_FILTER, filterUsers);
}
