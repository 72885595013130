import React from "react";

import { Campaign, UniquePromoCode } from "admin/store/campaigns";
import { exportCsv } from "../helpers/export";

import DataTable from "shared/components/table/DataTable";

const UniqueCodes = ({ campaign }: { campaign: Campaign }) => {
  const uniquePromoCodeColumns = [
    {
      header: "Code:",
      accessor: "promoCode",
    },
    {
      header: "Used:",
      accessor: (c: UniquePromoCode) => (c.countOfRedeemed > 0 ? "Yes" : "/"),
    },
  ];

  const handleExport = () => {
    exportCsv(campaign);
  };

  return (
    <div className="component-box with-header">
      <div className="component-box-header">
        <h2 className="mt-0">Unique Codes</h2>
        <button className="btn action filled" onClick={handleExport}>
          Export
        </button>
      </div>
      <div className="content">
        {!campaign.uniqueCodes && <h3>No unique codes.</h3>}
        {campaign.uniqueCodes && (
          <div className="list-table mt-2">
            <DataTable
              data={campaign.uniqueCodes}
              columns={uniquePromoCodeColumns}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UniqueCodes;
