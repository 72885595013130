import * as React from "react";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";

import { IAuthLogin, Schema } from "shared/store/auth/api";

import FieldError from "../elements/fieldError";
import FormButton from "../elements/FormButton";

export interface LoginFormProps {
  login: (u: IAuthLogin) => Promise<any>;
  fieldType: string;
}

const LoginFormTemplate = (props: LoginFormProps & FormikProps<IAuthLogin>) => {
  const { errors, touched, isSubmitting, fieldType } = props;
  const loading = isSubmitting ? "loading" : "";

  return (
    <Form>
      <div className="form-group">
        {errors.username && touched.username && (
          <FieldError message={errors.username} />
        )}
        <Field
          id="username"
          name="username"
          type={fieldType}
          className={
            errors.username && touched.username
              ? "form-control email with-error"
              : "form-control email"
          }
          maxLength={128}
          placeholder="Email Address"
        />
      </div>

      <div className="form-group">
        {errors.password && touched.password && (
          <FieldError message={errors.password} />
        )}
        <Field
          name="password"
          type="password"
          className={
            errors.password && touched.password
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={128}
          placeholder="Password"
        />
      </div>

      <FormButton text={"Login"} loading={loading} />
    </Form>
  );
};

const LoginForm = withFormik<LoginFormProps, IAuthLogin>({
  displayName: "LoginForm",
  handleSubmit: (
    values: IAuthLogin,
    formikBag: FormikBag<LoginFormProps, IAuthLogin>
  ) => {
    formikBag.props
      .login({ ...values } as IAuthLogin)
      .catch(e => {
        /**/
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: () => {
    return {
      username: "",
      password: ""
    };
  },
  validationSchema: Schema
})(LoginFormTemplate);

export default LoginForm;
