import { authSetAction } from "./actions";
import { AuthActionsTypes as actions } from "./types";
import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import * as Sentry from "@sentry/browser";

import {
  AuthMissing,
  cognitoUserToAuthState,
  getCognitoUser,
  logoutCognitoUser
} from "./api";

function* authFetchSaga() {
  try {
    const cognitoUser = yield call(getCognitoUser);
    const authState = yield call(cognitoUserToAuthState, cognitoUser);
    yield put(authSetAction({ ...authState, isLoaded: true }));
  } catch (e) {
    if (e instanceof AuthMissing) {
      yield put(
        authSetAction({
          isLoggedIn: false,
          user: undefined,
          isLoaded: true
        })
      );
    } else {
      Sentry.captureException(e);
      yield put(
        authSetAction({
          isLoaded: true,
          isLoggedIn: false,
          user: undefined
        })
      );
    }
  }
}

function* authLogoutSaga() {
  try {
    yield call(logoutCognitoUser);
  } catch (e) {
    Sentry.captureException(e);
  }

  delete axios.defaults.headers.common.Authorization;

  // cognito will do a logout at this point
  // so the next assign is not really helpful
  yield put(
    authSetAction({
      isLoggedIn: false,
      isLoaded: true,
      user: undefined
    })
  );
}

export function* authSagas() {
  yield takeLatest(actions.AUTH_FETCH, authFetchSaga);
  yield takeLatest(actions.AUTH_LOGOUT, authLogoutSaga);
}
