import React from "react";
import { ClientAdminData } from "admin/store/users";
import { timeToFormat, DateFormat } from "shared/helpers/date";

const UserInfoSection = ({ adminData }: { adminData: ClientAdminData }) => {
  return (
    <div className="component-box">
      <div className="content">
        <div className="section empty-section">
          <h3 className="small">
            Account was created on{" "}
            {timeToFormat(adminData.createdAt, DateFormat.NiceDateTime)}
          </h3>
        </div>
      </div>
    </div>
  );
};
export default UserInfoSection;
