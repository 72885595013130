import { Reducer } from "redux";
import { AuthActions } from "./actions";
import { AuthActionsTypes, AuthState } from "./types";

const initialState: AuthState = {
  isLoggedIn: false,
  user: undefined
};

const reducer: Reducer<AuthState, AuthActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AuthActionsTypes.AUTH_SET: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as authReducer };
