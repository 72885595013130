import * as Yup from "yup";
import {
  NAME_CANNOT_BE_BLANK,
  NAME_MUST_BE_LONGER_THAN,
  NAME_MUST_BE_SHORTER_THAN,
} from "../../helpers/messages";

export enum SubscriptionPlatform {
  Google = "google",
  Amazon = "amazon",
  Apple = "apple",
  Card = "card",
  Web = "web", // promotion
  Braintree = "braintree",
}

/**
 * API: client_api/lib/subscription/subscription.types.js
 */
export interface ClientDynamoUser {
  readonly username: string;
  readonly name: string;
  readonly lastName: string;
  readonly schoolName?: string;
  readonly email: string;
  readonly country: string;
  readonly appVersion: string;
  readonly analyticsOptIn: boolean;
  readonly twilightUpdateOptIn: boolean;
  readonly partnerUpdateOptIn: boolean;
  readonly optOutOfSale: boolean;
  readonly referralCode: string;
  [key: string]: undefined | boolean | string;
}

/**
 * API: client_api/lib/database/dynamo.types.js
 */
export interface ClientSubscriptionData {
  readonly subscriptionValid: boolean;
  readonly trialActive: boolean;
  readonly trialUsed: boolean;
  readonly startTimeMillis: number;

  readonly renewalTimeMillis: number;
  readonly renewalDetails: string;

  readonly platform: string;
  readonly codeRedeemed: string | null;
  readonly isSchoolSubscription?: boolean | null;
  readonly subscriptionId: string;
  readonly subscriptionType: string;
  readonly subscriptionStatusDetails: string;
  readonly isCancelled: boolean;

  readonly promotionDetails: string;
  readonly paymentDetails: string;
  readonly purchaseId: string;
}

/**
 * API: client_api/lib/cognito/devices.types.js
 */
export interface ClientCognitoDevice {
  readonly DeviceKey: string;
  readonly DeviceStatus: string;
  readonly DeviceName: string;
  readonly LastIpUsed: string;
  readonly LastAuthenticatedDate: number | null;
  readonly DeviceLastModifiedDate: number | null;
  readonly DeviceCreateDate: number | null;
}

/**
 * What POST /current-user accepts
 */
export interface ProfileUpdateParameters {
  name?: string;
  lastName?: string;
  schoolName?: string;
  email?: string; // only for admin mode
  country?: string;
  twilightUpdateOptIn?: boolean;
  analyticsOptIn?: boolean;
  partnerUpdateOptIn?: boolean;
  optOutOfSale?: boolean;
}

export const putCurrentUserPrivacySchema = Yup.object().shape({
  analyticsOptIn: Yup.boolean().required(),
  partnerUpdateOptIn: Yup.boolean().required(),
  twilightUpdateOptIn: Yup.boolean().required(),
  optOutOfSale: Yup.boolean().notRequired(),
});

export const putCurrentUserSettingsSchema = Yup.object().shape({
  // Not implemented
  email: Yup.string()
    .required(`This field can't be empty`)
    .email("Please enter valid email"),
  name: Yup.string()
    .required()
    .min(2, NAME_MUST_BE_LONGER_THAN)
    .max(25, NAME_MUST_BE_SHORTER_THAN)
    .required(NAME_CANNOT_BE_BLANK),
  lastName: Yup.string()
    .required()
    .min(2, NAME_MUST_BE_LONGER_THAN)
    .max(25, NAME_MUST_BE_SHORTER_THAN)
    .required(NAME_CANNOT_BE_BLANK),
  schoolName: Yup.string()
    .min(2, NAME_MUST_BE_LONGER_THAN)
    .max(50, NAME_MUST_BE_SHORTER_THAN),
});
