import { Reducer } from "redux";
import { UsersActions } from "./actions";
import { UsersActionsTypes, UsersState } from "./types";

const initialState: UsersState = {
  isLoading: false,
  query: "",
  users: [],
  status: null
};

const reducer: Reducer<UsersState, UsersActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UsersActionsTypes.USERS_SET:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export { reducer as usersReducer };
