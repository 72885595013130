import React, { useState, useEffect } from "react";

import { ClientDynamoUser } from "shared/store/user/types";
import { UserNotes } from "admin/store/users/types";

import UsersApi from "admin/store/users/api";

import Note from "./UserNote";
import AddUserNoteModal from "./AddUserNote";

const UserNotesSection = ({ userData }: { userData: ClientDynamoUser }) => {
  const [userNotes, setUserNotes] = useState<UserNotes>();
  useEffect(() => {
    const fetchData = async () => {
      if (userData) {
        setUserNotes(await UsersApi.getUserNotes(userData.username));
      }
    };
    fetchData().then();
  }, [userData]);

  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const closeAddNoteModal = () => {
    setShowAddNoteModal(false);
  };
  const updateAndCloseModal = (notes: UserNotes) => {
    setUserNotes(notes);
    closeAddNoteModal();
  };
  const openAddNoteModal = () => {
    setShowAddNoteModal(true);
  };

  return (
    <React.Fragment>
      <div className="component-box">
        <div className="content">
          <div className="section with-loading-block">
            <h2> User notes </h2>
            {userNotes &&
              Object.entries(userNotes)
                .sort((a, b) => {
                  return b[1].priority - a[1].priority;
                })
                .map(([noteId, note]) => (
                  <Note
                    key={noteId}
                    userNote={note}
                    username={userData.username}
                    noteId={noteId}
                    onUpdate={setUserNotes}
                  />
                ))}
            <div className="actions">
              <button
                className="btn admin filled wide"
                onClick={openAddNoteModal}
              >
                Add Note
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddUserNoteModal
        isOpen={showAddNoteModal}
        onClose={closeAddNoteModal}
        onUpdate={updateAndCloseModal}
        username={userData.username}
      />
    </React.Fragment>
  );
};

export default UserNotesSection;
