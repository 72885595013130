import React from "react";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";

import { ValidationError } from "shared/helpers/errors";
import {
  ProfileUpdateParameters,
  putCurrentUserSettingsSchema,
  ClientDynamoUser
} from "shared/store/user/types";
import countries from "shared/helpers/countries.json";

export interface EditFormProps {
  editMode: boolean;
  userDataUpdate: (u: ProfileUpdateParameters) => Promise<void>;
  userData: ClientDynamoUser;
}

const EditFormTemplateAdmin = (
  props: FormikProps<ProfileUpdateParameters> & EditFormProps
) => {
  const { touched, errors } = props;
  return (
    <Form className="content">
      <div className="data-group">
        <div className="label">Country</div>
        <div className="value">
          <Field component="select" name="country" placeholder="Country">
            {countries.map(c => {
              return (
                <option value={c.cca2} key={c.cca2}>
                  {`${c.flag} ${c.name}`}
                </option>
              );
            })}
          </Field>
          {errors.country && touched.country && (
            <div className="error-message">{errors.country}</div>
          )}
        </div>
      </div>
    </Form>
  );
};

const EditForm = withFormik({
  displayName: "SettingsForm",
  handleSubmit: (
    values: ProfileUpdateParameters,
    formikBag: FormikBag<EditFormProps, ProfileUpdateParameters>
  ) => {
    formikBag.props
      .userDataUpdate(values)
      .then(() => {
        formikBag.setSubmitting(false);
      })
      .catch(e => {
        if (e instanceof ValidationError && e.errors) {
          for (const field of e.errors) {
            formikBag.setFieldError(field.attribute, field.message);
          }
        }
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: (props: EditFormProps) => ({
    country: props.userData.country
  }),
  validationSchema: putCurrentUserSettingsSchema
})(EditFormTemplateAdmin);

export default EditForm;
