import { AuthActionsTypes, AuthState } from "./types";


export interface AuthFetch {
  type: AuthActionsTypes.AUTH_FETCH;
}


export interface AuthSet {
  type: AuthActionsTypes.AUTH_SET;
  payload: AuthState;
}

export interface AuthLogout {
  type: AuthActionsTypes.AUTH_LOGOUT;
}

// boilerplate..

export function authFetchAction(): AuthFetch {
  return {
    type: AuthActionsTypes.AUTH_FETCH
  };
}

export function authSetAction(authState: AuthState): AuthSet {
  return {
    type: AuthActionsTypes.AUTH_SET,
    payload: authState
  };
}

export function authLogoutAction(): AuthLogout {
  return {
    type: AuthActionsTypes.AUTH_LOGOUT
  };
}


export type AuthActions =
  | AuthFetch
  | AuthLogout
  | AuthSet;
