import * as Sentry from "@sentry/browser";
import { ReportDialogOptions, Severity } from "@sentry/browser";
import * as SentryIntegrations from "@sentry/integrations";

import { store } from "../../admin";

export function init() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

declare global {
  interface Window {
    logger: any;
    dataLayer: any;
  }
}

export function setSentryTag(name: string, value: string) {
  Sentry.configureScope((scope) => {
    scope.setTag(name, value);
  });
}

/**
 * Tools for QA and debugging,
 * use these in the browser console/inspector.
 */
window.logger = {
  // manually report a error with the report dialog
  report() {
    const { auth } = store.getState();
    Sentry.configureScope((scope) => {
      // prevent grouping
      scope.setFingerprint(["manual-report-" + Date.now()]);

      captureException(
        new Error(
          "Manual Report from " +
            (auth && auth.user ? auth.user.username : "anonymous")
        ),
        {
          category: "manual-report",
        }
      );
    });
  },
  // trigger DevTools debugger instead of using console.log
  debugger() {
    Sentry.getCurrentHub().bindClient(
      // @ts-ignore
      new Sentry.BrowserClient({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
          new SentryIntegrations.Debug({
            debugger: true,
          }),
        ],
      })
    );
  },
};

interface CaptureMessageParams {
  readonly level: Sentry.Severity;
  readonly category?: string;
  readonly extra?: any;
}

interface CaptureBreadcrumbParams {
  readonly message?: string;
  readonly level?: Sentry.Severity;
  readonly category: string;
  readonly data?: any;
}

interface CaptureExceptionParams {
  readonly message?: string;
  readonly category?: string;
  readonly extra?: any;
  readonly report?: boolean;
  readonly showReportDialog?: boolean;
}

export function captureMessage(
  message: string,
  { level, category, extra }: CaptureMessageParams
) {
  Sentry.withScope((scope) => {
    if (extra) {
      scope.setExtra("extra", extra);
    }
    if (category) {
      scope.setTag("category", category);
    }
    Sentry.captureMessage(message, level);
  });
  return null;
}

export function captureBreadcrumb({
  level,
  category,
  data,
  message,
}: CaptureBreadcrumbParams) {
  Sentry.withScope((scope) => {
    Sentry.addBreadcrumb({
      category,
      data,
      level,
      message,
    });
  });
}

export function captureException(
  e: Error,
  { category, extra, message, showReportDialog }: CaptureExceptionParams
) {
  Sentry.withScope((scope) => {
    if (extra) {
      scope.setExtra("extra", extra);
    }
    if (message) {
      scope.setExtra("message", message);
    }
    if (category) {
      scope.setTag("category", category);
    }
    const eventId = Sentry.captureException(e);

    // tslint:disable-next-line:no-console
    console.info("&#1F997; Looks like you've caught a bug: " + eventId);

    if (showReportDialog) {
      reportDialog({
        eventId,
      });
    }
  });
}

export function reportDialog(options: ReportDialogOptions) {
  const { auth } = store.getState();
  Sentry.showReportDialog({
    user: {
      name: auth.user ? auth.user.username : "",
      email: auth.user ? auth.user.email : "",
    },
    ...options,
  });
}

export default {
  init,
};
