import React from "react";

import * as Yup from "yup";
import moment from "moment";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";

import { ValidationError } from "shared/helpers/errors";

import { AuthState } from "shared/store/auth";
import { Permissions } from "admin/helpers/permissions";
import {
  Campaign,
  CampaignUpdateParameters,
  PromoCodeType,
  PromoCodeTypeLabels,
  RedeemTypeLabels
} from "admin/store/campaigns";
import { RedeemType } from "shared/store/campaigns/types";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";

const EditCampaignSchema = Yup.object().shape({
  campaignUID: Yup.string(),
  campaignName: Yup.string()
    .min(4)
    .max(30)
    .required(`This field can't be empty`),
  startOfCampaign: Yup.date().required(`This field can't be empty`),
  endOfCampaign: Yup.date(),
  type: Yup.string().required(`This field can't be empty`),
  numberOfCodes: Yup.number().required("Please enter the amount of codes!"),
  promoDurationInDays: Yup.number().required(
    "Please enter the duration of this code!"
  ),
  promoCode: Yup.string().when("type", {
    is: PromoCodeType.GENERIC,
    then: Yup.string()
      .matches(/[A-Z0-9]{6}/, "Wrong format")
      .required(`This field can't be empty`),
    otherwise: Yup.string()
  }),
  redeemType: Yup.string().required(`This field can't be empty`)
});

interface EditFormProps {
  campaign: Campaign | null;
  auth: AuthState;
  isLoading: boolean;
  saveAction: (d: CampaignUpdateParameters) => Promise<void>;
  cancelAction: () => void;
}

const EditFormTemplate = (
  props: FormikProps<CampaignUpdateParameters> & EditFormProps
) => {
  const { touched, cancelAction, errors, isSubmitting, campaign, auth } = props;
  const loading = isSubmitting ? "loading" : "";
  const validateAmount = (value: number) => {
    if (!props.campaign) {
      return null;
    }
    let error = null;

    const oldNumberOfCodes = props.campaign.numberOfCodes
      ? props.campaign.numberOfCodes
      : 0;

    if (oldNumberOfCodes > 0) {
      if (oldNumberOfCodes > value) {
        error = `New value should be larger than ${oldNumberOfCodes}!`;
      }
    }

    if (props.values.type === "unique-campaign") {
      if (value - oldNumberOfCodes > 200) {
        error = `You can generate 200 new promo codes at a time!`;
      }
    }
    return error;
  };

  let redeemTypes = Object.entries(RedeemTypeLabels);

  if (!campaign) {
    const permissions = new Permissions(auth);
    redeemTypes = redeemTypes.filter(([id, _]) => {
      return permissions.can(`campaigns.edit.add-${id}`);
    });
  }

  return (
    <Form className={`section ${loading}`}>
      <div className="content">
        <div className="form-group">
          <span className="label">Campaign name</span>
          <div className="value">
            <Field type="text" name="campaignName" />
            {errors.campaignName && touched.campaignName && (
              <div className="error-message">{errors.campaignName}</div>
            )}
          </div>
        </div>

        <div className="form-group">
          <div className="label">Start date</div>
          <div className="value">
            <Field type="date" name="startOfCampaign" disabled={campaign} />
            {errors.startOfCampaign && touched.startOfCampaign && (
              <div className="error-message">{errors.startOfCampaign}</div>
            )}
          </div>
        </div>

        <div className="form-group">
          <div className="label">End date</div>
          <div className="value">
            <Field type="date" name="endOfCampaign" />
            {errors.endOfCampaign && touched.endOfCampaign && (
              <div className="error-message">{errors.endOfCampaign}</div>
            )}
          </div>
        </div>

        <div className="form-group">
          <div className="label">Type</div>
          <div className="value">
            <Field
              component="select"
              name="type"
              placeholder=""
              disabled={campaign}
            >
              <option value="">[none selected]</option>
              {Object.entries(PromoCodeTypeLabels).map(([key, value]) => {
                return (
                  <option value={key} key={key}>
                    {value}
                  </option>
                );
              })}
            </Field>
            {errors.type && touched.type && (
              <div className="error-message">{errors.type}</div>
            )}
          </div>
        </div>

        <div className="form-group">
          <div className="label">Amount</div>
          <div className="value">
            <Field
              type="number"
              name="numberOfCodes"
              validate={validateAmount}
            />
            {errors.numberOfCodes && touched.numberOfCodes && (
              <div className="error-message">{errors.numberOfCodes}</div>
            )}
          </div>
        </div>

        <div className="form-group">
          <div className="label">Trial duration (in days)</div>
          <div className="value">
            <Field type="number" name="promoDurationInDays" />
            {errors.promoDurationInDays && touched.promoDurationInDays && (
              <div className="error-message">{errors.promoDurationInDays}</div>
            )}
          </div>
        </div>

        <div className="form-group">
          <div className="label">Redeem type</div>
          <div className="value">
            <Field
              component="select"
              name="redeemType"
              placeholder=""
              disabled={campaign}
            >
              <option value="">[none selected]</option>
              {redeemTypes.map(([key, value]) => {
                return (
                  <option value={key} key={key}>
                    {value}
                  </option>
                );
              })}
            </Field>
            {errors.redeemType && touched.redeemType && (
              <div className="error-message">{errors.redeemType}</div>
            )}
          </div>
        </div>

        {props.values.type === PromoCodeType.GENERIC && (
          <div className="form-group">
            <div className="label">Promo code</div>
            <div className="value">
              <Field type="text" name="promoCode" />
              {errors.promoCode && touched.promoCode && (
                <div className="error-message">{errors.promoCode}</div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="actions">
        <button className="btn action filled" type="submit">
          Save
        </button>
        <button
          type="button"
          className="btn admin filled"
          onClick={cancelAction}
        >
          Cancel
        </button>
        {loading && <LoadingIndicator />}
      </div>
    </Form>
  );
};

const EditCampaignForm = withFormik({
  displayName: "CampaignForm",
  handleSubmit: (
    values: CampaignUpdateParameters,
    formikBag: FormikBag<EditFormProps, CampaignUpdateParameters>
  ) => {
    formikBag.props
      .saveAction(values)
      .then(() => {
        formikBag.setSubmitting(false);
      })
      .catch(e => {
        // maybe we got some validation errors back?
        if (e instanceof ValidationError && e.errors) {
          for (const field of e.errors) {
            formikBag.setFieldError(field.attribute, field.message);
          }
        }
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: (props: EditFormProps) => {
    if (!props.campaign) {
      return {
        campaignName: "",
        startOfCampaign: moment().format("YYYY-MM-DD"),
        endOfCampaign: "",
        type: "",
        numberOfCodes: 0,
        promoDurationInDays: 0,
        redeemType: RedeemType.FREE_ACCESS_CODE.toString()
      } as CampaignUpdateParameters;
    }
    return {
      campaignUID: props.campaign.campaignUID,
      campaignName: props.campaign.campaignName,
      startOfCampaign: props.campaign.startOfCampaign
        ? moment(props.campaign.startOfCampaign).format("YYYY-MM-DD")
        : "",
      endOfCampaign: props.campaign.endOfCampaign
        ? moment(props.campaign.endOfCampaign).format("YYYY-MM-DD")
        : "",
      type: props.campaign.type || "",
      numberOfCodes: props.campaign.numberOfCodes,
      promoDurationInDays: props.campaign.promoDurationInDays,
      promoCode: props.campaign.uniqueCodes[0].promoCode,
      redeemType: props.campaign.redeemType || ""
    } as CampaignUpdateParameters;
  },
  validationSchema: EditCampaignSchema
})(EditFormTemplate);

export default EditCampaignForm;
