import React, { useState, useEffect } from "react";
import ModalConfirm from "shared/components/modal/ModalConfirm";
import SubscriptionHistoryModal from "./SubscriptionHistory";
import { toast } from "react-toastify";
import { labelize } from "shared/store/user/helpers";
import {
  ClientSubscriptionData,
  SubscriptionPlatform,
  ClientDynamoUser,
} from "shared/store/user/types";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import SubscriptionSectionShared from "shared/components/users/sections/Subscription";
import { ClientAdminData, FullUserState } from "admin/store/users";
import { captureException } from "../../../helpers/logger";
import UsersApi from "../../../store/users/api";

const SubscriptionSection = ({ user }: { user: FullUserState }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [userData, setUserData] = useState<ClientDynamoUser>();
  const [subscriptionData, setSubscriptionData] =
    useState<ClientSubscriptionData>();
  const [adminData, setAdminData] = useState<ClientAdminData>();
  useEffect(() => {
    if (user) {
      if (user.UserData) {
        setUserData(user.UserData);
      }
      if (user.SubscriptionData) {
        setSubscriptionData(user.SubscriptionData);
      }
      if (user.AdminData) {
        setAdminData(user.AdminData);
      }
    }
  }, [user]);

  const [labels, setLabels] = useState();
  useEffect(() => {
    if (subscriptionData) {
      setLabels(labelize(subscriptionData));
      setIsLoading(false);
    }
  }, [subscriptionData, isLoading]);

  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const closeHistoryModal = () => {
    setShowHistoryModal(false);
  };
  const openHistoryModal = () => {
    setShowHistoryModal(true);
  };

  const [modalQuestion, setModalQuestion] = useState<string | null>(null);
  const [onModalConfirm, setOnModalConfirm] = useState<(() => void) | null>();
  const closeConfirmModal = () => {
    setModalQuestion(null);
  };

  const confirmCancelBraintreeSubscription = () => {
    if (
      !subscriptionData ||
      subscriptionData.platform !== SubscriptionPlatform.Braintree
    ) {
      throw new Error("Not a Braintree subscription");
    }
    setModalQuestion("Are you sure you want to cancel this subscription?");
    setOnModalConfirm(() => cancelBraintreeSubscription);
  };

  const cancelBraintreeSubscription = async () => {
    try {
      setIsLoading(true);
      if (userData && userData.username) {
        await UsersApi.cancelSubscription(userData.username);
      } else {
        toast.error("No User");
      }
    } catch (e) {
      if (e.message) {
        toast.error(e.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    } finally {
      closeConfirmModal();
      setIsLoading(false);
    }
  };

  const confirmRefundUser = () => {
    setModalQuestion(
      "Are you sure you want to refund this user's subscription?"
    );
    setOnModalConfirm(() => refundUser);
  };

  const refundUser = async () => {
    if (userData && userData.username) {
      setIsLoading(true);
      try {
        const response = await UsersApi.refundSubscription(userData.username);
        if (response.SubscriptionData) {
          setSubscriptionData(subscriptionData);
        }
        toast.success(`Refunded ${response.refundData.refundAmount}.`);
      } catch (e) {
        captureException(e, { category: "admin.user.refund" });
        toast.error(e.message);
      } finally {
        closeConfirmModal();
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="component-box">
        <div className={`section subscription-section with-loading-block`}>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <div className="content">
              <SubscriptionSectionShared
                subscriptionData={subscriptionData!}
                labels={labels}
              />
              {adminData && adminData.purchaseId && (
                <div className="data-group">
                  <label>Purchase id</label>
                  <div className="value">{adminData.purchaseId}</div>
                </div>
              )}
              {adminData && adminData.trialCount && (
                <div className="data-group">
                  <label>Trial count</label>
                  <div className="value">{adminData.trialCount}</div>
                </div>
              )}
              <div className="actions mt-3">
                {labels.showCancel && (
                  <button
                    onClick={confirmCancelBraintreeSubscription}
                    className="btn secondary filled wide"
                  >
                    Cancel subscription
                  </button>
                )}
                {adminData && adminData.subscriptionHistory && (
                  <button
                    className="btn admin filled wide"
                    onClick={openHistoryModal}
                  >
                    History
                  </button>
                )}
                {subscriptionData &&
                  subscriptionData.subscriptionValid &&
                  subscriptionData.platform ===
                    SubscriptionPlatform.Braintree && (
                    <button
                      onClick={confirmRefundUser}
                      className="btn admin filled wide"
                    >
                      Refund
                    </button>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalConfirm
        question={modalQuestion}
        confirmText="Yes"
        loading={isLoading}
        close={closeConfirmModal}
        onConfirm={onModalConfirm}
      />

      {adminData && (
        <SubscriptionHistoryModal
          isOpen={showHistoryModal}
          onClose={closeHistoryModal}
          adminData={adminData}
        />
      )}
    </React.Fragment>
  );
};

export default SubscriptionSection;
