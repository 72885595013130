import { Action, AnyAction, combineReducers, Dispatch } from "redux";
import { all, fork } from "redux-saga/effects";

import { authReducer, authSagas, AuthState } from "shared/store/auth";
import { campaignsReducer, campaignsSagas, CampaignsState } from "./campaigns";
import { usersReducer, usersSagas, UsersState } from "./users";

// The top-level state object
export interface ApplicationState {
  auth: AuthState;
  users: UsersState;
  campaigns: CampaignsState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const rootReducer = combineReducers<ApplicationState>({
  auth: authReducer,
  campaigns: campaignsReducer,
  users: usersReducer
});

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([fork(authSagas), fork(campaignsSagas), fork(usersSagas)]);
}
