import React from "react";

const FieldError = ({ message }: { message: string }) => {
  return (
    <div className="error-message pill">
      {message}
      <div className="down-arrow" />
    </div>
  );
};

export default FieldError;
