import React, { useState } from "react";
import { ClientDynamoUser, ClientCognitoDevice } from "clients/store/profile";
import UsersApi from "../../../store/users/api";
import { toast } from "react-toastify";
import DevicesSectionBase from "shared/components/users/sections/Devices";

const DevicesSection = ({
  userData,
  devices
}: {
  userData: ClientDynamoUser;
  devices: ClientCognitoDevice[] | undefined;
}) => {
  const [deviceList, setDeviceList] = useState<
    ClientCognitoDevice[] | undefined
  >(devices);

  const deleteDevice = async (deviceKey: string) => {
    try {
      if (userData && userData.username) {
        await UsersApi.delDevice(userData.username, deviceKey);
        setDeviceList(
          deviceList!.filter(device => device.DeviceKey !== deviceKey)
        );
        toast.success("Device removed");
      } else {
        throw new Error("No User");
      }
    } catch (e) {
      toast.error(e.toString());
    }
  };

  return (
    <DevicesSectionBase devices={deviceList} deleteDevice={deleteDevice} />
  );
};

export default DevicesSection;
