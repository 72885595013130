import React, { ComponentType, useState } from "react";

const withInput = (Component: ComponentType) => {
  return (props: any) => {
    const [input, setInput] = useState("");

    const onChange = (set: (arg0: any) => void) => (event: {
      target: { value: any };
    }) => {
      const { value } = event.target;
      set(value);
    };

    const onClick = (confirm: (arg0: string) => void) => (_: any) => {
      confirm(input);
    };

    return <Component {...{ ...props, input, setInput, onClick, onChange }} />;
  };
};

export default withInput;
