import React from "react";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";

import {
  putCurrentUserSettingsSchema,
  ClientDynamoUser,
  ProfileUpdateParameters
} from "shared/store/user/types";
import { ValidationError } from "shared/helpers/errors";

import FieldError from "../../../../shared/components/forms/elements/fieldError";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";

interface EditFormProps {
  editMode: boolean;
  switchMode: () => void;
  hasSchoolSubscription: boolean;
  userDataUpdate: (u: ProfileUpdateParameters) => Promise<void>;
  userData: ClientDynamoUser;
}

const EditFormTemplate = (
  props: FormikProps<ProfileUpdateParameters> & EditFormProps
) => {
  const {
    touched,
    switchMode,
    errors,
    isSubmitting,
    editMode,
    hasSchoolSubscription
  } = props;
  const cancel = () => {
    props.resetForm();
    switchMode();
  };
  return (
    <Form className="content">
      <h1 className="text-center">Profile settings</h1>
      <div className="form-group">
        {errors.name && touched.name && <FieldError message={errors.name} />}
        <Field
          id="name"
          name="name"
          type="text"
          className={
            errors.name && touched.name
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={25}
          placeholder="First Name"
          disabled={!editMode}
        />
      </div>
      <div className="form-group">
        {errors.lastName && touched.lastName && (
          <FieldError message={errors.lastName} />
        )}
        <Field
          id="lastName"
          name="lastName"
          type="text"
          className={
            errors.lastName && touched.lastName
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={25}
          placeholder="Last Name"
          disabled={!editMode}
        />
      </div>
      {(hasSchoolSubscription || props.userData.schoolName) && (
        <div className="form-group">
          {errors.schoolName && touched.schoolName && (
            <FieldError message={errors.schoolName} />
          )}
          <Field
            id="schoolName"
            name="schoolName"
            type="text"
            className={
              errors.schoolName && touched.schoolName
                ? "form-control with-error"
                : "form-control"
            }
            maxLength={50}
            placeholder="School Name"
            disabled={!editMode}
          />
        </div>
      )}
      <div className="form-group">
        {errors.email && touched.email && <FieldError message={errors.email} />}
        <Field
          id="email"
          name="email"
          type="email"
          autoCapitalize="none"
          className={
            errors.email && touched.email
              ? "form-control email with-error"
              : "form-control email"
          }
          maxLength={128}
          placeholder="Email Address"
          disabled={!editMode}
        />
      </div>
      {!editMode && (
        <div className={`form-group with-loading-block`}>
          <div className="actions">
            <button
              className="btn secondary filled"
              type="button"
              onClick={switchMode}
            >
              Edit
            </button>
          </div>
        </div>
      )}
      {editMode && (
        <div className={`form-group with-loading-block`}>
          {(isSubmitting && <LoadingIndicator />) || (
            <div className="actions">
              <button className="btn action filled" type="submit">
                Save
              </button>
              <button className="btn secondary" type="button" onClick={cancel}>
                Cancel
              </button>
            </div>
          )}
        </div>
      )}
    </Form>
  );
};

const EditForm = withFormik({
  displayName: "SettingsForm",
  handleSubmit: (
    values: ProfileUpdateParameters,
    formikBag: FormikBag<EditFormProps, ProfileUpdateParameters>
  ) => {
    formikBag.props
      .userDataUpdate(values)
      .then(() => {
        formikBag.setSubmitting(false);
        formikBag.props.switchMode();
      })
      .catch(e => {
        if (e instanceof ValidationError && e.errors) {
          for (const field of e.errors) {
            formikBag.setFieldError(field.attribute, field.message);
          }
        }
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: (props: EditFormProps) => ({
    name: props.userData.name,
    lastName: props.userData.lastName,
    schoolName: props.userData.schoolName || undefined,
    email: props.userData.email,
    country: props.userData.country
  }),
  validationSchema: putCurrentUserSettingsSchema
})(EditFormTemplate);

export default EditForm;
