import React from "react";
import Modal from "react-modal";

import { addNewUserNote } from "admin/store/users/api";
import { UserNotes, AddUserNoteParameters } from "admin/store/users";

import { toast } from "react-toastify";

import AddUserNoteForm from "./forms/AddUserNoteForm";

import "./UserNoteModal.scss";

const AddUserNoteModal = ({
  isOpen,
  onClose,
  onUpdate,
  username
}: {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (notes: UserNotes) => void;
  username: string;
}) => {
  Modal.setAppElement("#root");

  const save = async (parameters: AddUserNoteParameters) => {
    try {
      const updatedNotes = await addNewUserNote(username, parameters);
      toast.success("Added new note to user");
      onUpdate(updatedNotes);
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="Modal-big"
      contentLabel="Edit User Note"
    >
      <h2>
        Add new note
        <div onClick={onClose} className="close">
          X
        </div>
      </h2>
      <AddUserNoteForm save={save} />
    </Modal>
  );
};

export default AddUserNoteModal;
