import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";

import { ClientDynamoUser } from "shared/store/user/types";
import UsersApi from "admin/store/users/api";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";
const DataTable = React.lazy(() => import("shared/components/table/DataTable"));

function ReferredUsersListModal({
  isOpen,
  onClose,
  referredUserIds,
}: {
  isOpen: boolean;
  onClose: () => void;
  referredUserIds: string[];
}) {
  const [referredUsers, setReferredUsers] = useState<ClientDynamoUser[]>([]);
  const fetchUsers = useCallback(async () => {
    const userArray = [];
    for (const username of referredUserIds) {
      try {
        const user = await UsersApi.getUser(username);
        if (user.UserData) {
          userArray.push(user.UserData);
        }
      } catch (e) {
        // log bad user
      }
    }
    setReferredUsers(userArray);
  }, [referredUserIds]);

  useEffect(() => {
    fetchUsers().finally();
  }, [referredUserIds, fetchUsers]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="Modal-small"
      contentLabel="Referred users"
    >
      <h2 className="text-center">Referred users</h2>
      <React.StrictMode>
        <React.Suspense fallback={<LoadingIndicator />}>
          <DataTable data={referredUsers} columns={referredUserColumns} />
        </React.Suspense>
      </React.StrictMode>
    </Modal>
  );
}

export default ReferredUsersListModal;

const referredUserColumns = [
  {
    header: "Name",
    accessor: (u: ClientDynamoUser) => u.name + " " + u.lastName,
  },
  {
    header: "UID",
    accessor: "username",
  },
  {
    header: "Email",
    accessor: "email",
  },
];
