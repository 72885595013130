import React, { useState } from "react";

import "./Header.scss";

const Header = ({
  children,
  isSchoolSubscription = false
}: {
  children: any;
  isSchoolSubscription?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={"app-header " + (isOpen ? "is-open" : "")}>
      <div className="container">
        <div className="header-logo">
          <a
            href={process.env.REACT_APP_APP_URL}
            className="ga-event"
            id="ga-link-home"
          >
            <img
              src="/media/logo_header.png"
              alt="Sleep by Moshi"
              className="Header-branding-logo"
            />
          </a>
        </div>
        <div className="mobile-menu-toggle" onClick={toggleMenu}>
          &#x2630;
        </div>
        <div className="header-links">
          <a
            href="https://www.moshikids.com/about"
            target="_blank"
            rel="noopener noreferrer"
          >
            About
          </a>
          <a
            href="https://www.moshikids.com/howithelps"
            target="_blank"
            rel="noopener noreferrer"
          >
            How it helps
          </a>
          <a
            href={
              "https://www.moshikids.com/moshi-for-schools" +
              (isSchoolSubscription ? "/#lessonresources" : "")
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Schools
          </a>
          <a
            href="https://www.moshikids.com/blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </div>
        <nav className="header-actions">{children}</nav>
      </div>
    </header>
  );
};

export default Header;
