import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router";
import LoginForm from "shared/components/forms/auth/LoginForm";
import PasswordChangeForm from "shared/components/forms/auth/resetPassword/PasswordChangeForm";
import { ApplicationState } from "../../store";
import {
  cognitoUserToAuthState,
  completeNewPassword,
  IAuthChangePassword,
  IAuthLogin,
  loginCognitoUser,
  NewPasswordRequired,
  PasswordResetRequired
} from "shared/store/auth/api";
import { AuthState } from "shared/store/auth";
import { toast } from "react-toastify";
import * as authActions from "shared/store/auth/actions";
import { captureException, captureMessage } from "admin/helpers/logger";
import { Severity } from "@sentry/types";

interface PropsFromDispatch {
  authSet: typeof authActions.authSetAction;
}

interface PageState {
  completePassCognitoUser: any | null;
}

class Page extends React.Component<
  PropsFromDispatch & RouteComponentProps,
  PageState
> {
  constructor(props: PropsFromDispatch & RouteComponentProps) {
    super(props);
    this.state = {
      completePassCognitoUser: null
    };
  }

  public render() {
    const { completePassCognitoUser } = this.state;
    const setCompletePassword = this.setCompletePassword.bind(this);
    const login = this.login.bind(this);
    return (
      <div className="app-body narrow">
        <div className="component-box mt-2">
          {completePassCognitoUser && (
            <PasswordChangeForm changePassword={setCompletePassword} />
          )}
          {!completePassCognitoUser && (
            <LoginForm fieldType="text" login={login} />
          )}
        </div>
      </div>
    );
  }

  private async login(loginData: IAuthLogin) {
    try {
      const cognitoUser = await loginCognitoUser(loginData);
      const authState = await cognitoUserToAuthState(cognitoUser);
      this.props.authSet({ isLoaded: true, ...authState });
    } catch (e) {
      if (e instanceof PasswordResetRequired) {
        toast.error(
          "A password reset is required. Please contact an administrator."
        );
        return;
      } else if (e instanceof NewPasswordRequired) {
        toast.warn(e.message);
        this.setState({ completePassCognitoUser: e.cognitoUser });
        return;
      }
      toast.error(e.message);
      throw e;
    }
  }

  private async setCompletePassword(data: IAuthChangePassword) {
    if (!this.state.completePassCognitoUser) {
      captureMessage("No Cognito user.", {
        level: Severity.Critical,
        category: "auth.complete-password"
      });
      toast.error("Could not complete password reset.");
    } else {
      completeNewPassword(this.state.completePassCognitoUser, data.password)
        .then(cognitoUserToAuthState)
        .then(authState => {
          this.props.authSet({ isLoaded: true, ...authState });
        })
        .catch(e => {
          toast.error(e.message);
          captureException(e, { category: "auth.complete-password" });
        });
    }
  }
}

export const LoginPage = connect<any, PropsFromDispatch, any, ApplicationState>(
  null,
  (dispatch: Dispatch) => ({
    authSet: (authState: AuthState) =>
      dispatch(authActions.authSetAction(authState))
  })
)(Page);
