import React, { useState } from "react";

import { ReferralCodeData } from "admin/store/users";

import ReferredUsersListModal from "./ReferredUsersList";

const Referrals = ({
  referralCodeData,
}: {
  referralCodeData?: ReferralCodeData;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const showReferredUsers = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="component-box">
      <div className="content">
        <div className="section with-loading-block">
          <h1 className="text-center">Referrals</h1>

          <React.Fragment>
            <div className="data-group">
              <label>Referred count</label>
              <div className="value">
                {referralCodeData ? referralCodeData.amountRedeemed : 0}
              </div>
            </div>
            {referralCodeData && referralCodeData.redeemersUIDs.length > 0 && (
              <React.Fragment>
                <div className="actions">
                  <button
                    className="btn admin filled wide"
                    onClick={showReferredUsers}
                  >
                    More info
                  </button>
                </div>

                <ReferredUsersListModal
                  isOpen={isOpen}
                  onClose={closeModal}
                  referredUserIds={referralCodeData.redeemersUIDs}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
