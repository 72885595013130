import React from "react";

import { FullUserState } from "admin/store/users";

import DoubleSubscription from "./DoubleSubscription";

import "./Warnings.scss";

const Warnings = ({ user }: { user: FullUserState }) => {
  return (
    <React.Fragment>
      {user.AdminData!.didEmailDecline && (
        <div className="component-box warning">
          <div className="section empty-section">
            <div className="content">
              <h1>Email sent to this account has bounced!</h1>
            </div>
          </div>
        </div>
      )}
      {user.AdminData!.doubleSubscriptionData && (
        <div className="component-box warning">
          <DoubleSubscription
            firstPurchaseId={
              user.AdminData!.doubleSubscriptionData.mobilePurchaseId
            }
            secondPurchaseId={
              user.AdminData!.doubleSubscriptionData.braintreePurchaseId
            }
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Warnings;
