// we're going to remove moment.js at one point, this helper abstracts the lib

import moment, { unitOfTime } from "moment";

export enum DateFormat {
  ShortDate = "ddd MMM D, YYYY",
  // eslint-disable-next-line no-useless-escape
  NiceDateTime = "ddd MMM D, YYYY \\a\\t h:mm a\t"
}

export function timeToFormat(time: number | Date | string, format: DateFormat) {
  switch (format) {
    case DateFormat.ShortDate:
      return moment(time).format(DateFormat.ShortDate);
    case DateFormat.NiceDateTime:
      return moment(time).format(DateFormat.NiceDateTime);
    default:
      return moment(time).format();
  }
}

export function timeSpan(
  startTime: number | Date | string,
  endTime: number | Date | string,
  unit: unitOfTime.Diff
) {
  const start = moment(startTime);
  const end = moment(endTime);
  return end.diff(start, unit);
}
