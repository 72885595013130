import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import qs from "query-string";

import { ApplicationState } from "../../store";
import { usersFilterAction } from "../../store/users";
import LoadingIndicator from "../../../shared/components/loading/LoadingIndicator";
import { UsersState } from "../../store/users";

import SearchBar from "../searchBar/SearchBar";

interface PropsFromState {
  users: UsersState;
}

interface PropsFromDispatch {
  filterUsers: typeof usersFilterAction;
}

interface PageState {
  query: string;
}

class Page extends React.Component<
  PropsFromState & PropsFromDispatch & RouteComponentProps,
  PageState
> {
  constructor(props: PropsFromState & PropsFromDispatch & RouteComponentProps) {
    super(props);
    this.state = {
      query: "",
    };
  }

  public componentDidMount() {
    const search = qs.parse(this.props.location.search);
    if (search && search.query) {
      const query = search.query ? search.query.toString() : "";
      if (query !== this.props.users.query) {
        this.updateQuery(query);
      }
    } else {
      this.updateQuery("");
    }
  }

  public updateQuery(query: string) {
    if (query.length >= 1) {
      this.props.history.push({
        search: "?query=" + query,
      });
      this.setState({ query });
      this.props.filterUsers(query);
    }
  }

  public render() {
    const { isLoading, query, users } = this.props.users;
    const { history } = this.props;
    const updateQuery = this.updateQuery.bind(this);

    const handleClick = (event: React.SyntheticEvent<HTMLTableRowElement>) => {
      const username = event.currentTarget.dataset.uid;
      if (username) {
        history.push(`/users/${username}`);
      }
    };

    return (
      <div className="app-body wide users-page">
        <div className="components-wrap">
          <SearchBar input={updateQuery} value={query} />

          {isLoading && (
            <div className="app-body" title="Loading users..">
              <LoadingIndicator />
            </div>
          )}

          {!isLoading && !query && (
            <div className="text-center mt-2 large">Search for users</div>
          )}

          {!isLoading && query && (!users || users.length) < 1 && (
            <div className="text-center mt-2 large">No users found</div>
          )}

          {!isLoading && users && users.length > 0 && (
            <div className="list-table">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>UID</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((u, i) => {
                    return (
                      <tr key={i} data-uid={u.UID} onClick={handleClick}>
                        <td id="name">
                          <span className="cell-header">Name:</span>
                          {u.name ? u.name : "/"}
                        </td>
                        <td id="uid">
                          <span className="cell-header">UID:</span>
                          {u.UID ? u.UID : "/"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const UsersListPage = connect<
  PropsFromState,
  PropsFromDispatch,
  any,
  ApplicationState
>(
  ({ users }: ApplicationState) => ({
    users,
  }),
  (dispatch: Dispatch) => ({
    filterUsers: (searchString: string) =>
      dispatch(usersFilterAction(searchString)),
  })
)(Page);
