import * as React from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import Header from "./components/header/Header";
import Router from "./Router";

class App extends React.PureComponent {
  public render() {
    return (
      <div className="app-container">
        <ToastContainer autoClose={3000} />
        <Header />
        <Router />
      </div>
    );
  }
}

export default App;
