import { UsersActionsTypes, UsersState } from "./types";

export interface UsersSet {
  type: UsersActionsTypes.USERS_SET;
  payload: Partial<UsersState>;
}

export interface UsersFilter {
  type: UsersActionsTypes.USERS_FILTER;
  payload: string;
}

export function usersSetAction(users: UsersState): UsersSet {
  return {
    type: UsersActionsTypes.USERS_SET,
    payload: users
  };
}

export function usersFilterAction(searchString: string): UsersFilter {
  return {
    payload: searchString,
    type: UsersActionsTypes.USERS_FILTER
  };
}

export type UsersActions = UsersSet | UsersFilter;
