import React from "react";
import * as Yup from "yup";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";

import {
  EditUserNoteParameters,
  UserNotePriorityMap,
  UserNoteState,
  UserNote
} from "admin/store/users/types";

import FieldError from "shared/components/forms/elements/fieldError";
import FormButton from "shared/components/forms/elements/FormButton";

const EditUserNoteValidationSchema = Yup.object().shape({
  comment: Yup.string(),
  state: Yup.string(),
  priority: Yup.number()
});

interface EditUserNoteFormProps {
  save: (params: EditUserNoteParameters) => Promise<any>;
  userNote: UserNote;
}

const EditUserNoteFormTemplate = (
  props: FormikProps<EditUserNoteParameters> & EditUserNoteFormProps
) => {
  const { touched, errors, isSubmitting, userNote } = props;
  const loading = isSubmitting ? "loading" : "";

  const stateMap = [
    UserNoteState.OPEN,
    UserNoteState.CLOSED,
    UserNoteState.ESCALATED
  ];

  return (
    <Form>
      <div className="form-group">
        <h3>{userNote.title}</h3>
        <div className="data-group">
          <div className="label">State</div>
          <div className="value">
            <Field component="select" name="state" placeholder={userNote.state}>
              {stateMap.map((value, i) => {
                return (
                  <option value={i} key={i}>
                    {value}
                  </option>
                );
              })}
            </Field>
            {errors.state && touched.state && (
              <div className="error-message">{errors.state}</div>
            )}
          </div>
        </div>
        <div className="data-group">
          <div className="label">Priority</div>
          <div className="value">
            <Field component="select" name="priority" placeholder="Priority">
              {UserNotePriorityMap.map((value, i) => {
                return (
                  <option value={i} key={i}>
                    {value}
                  </option>
                );
              })}
            </Field>
            {errors.priority && touched.priority && (
              <div className="error-message">{errors.priority}</div>
            )}
          </div>
          {errors.comment && touched.comment && (
            <FieldError message={errors.comment} />
          )}
          <Field
            id="comment"
            name="comment"
            type="text"
            className={
              errors.comment && touched.comment
                ? "form-control with-error"
                : "form-control"
            }
            placeholder="Comment"
          />
        </div>
        <FormButton text="Save" loading={loading} />
      </div>
    </Form>
  );
};

const AddUserNoteForm = withFormik<
  EditUserNoteFormProps,
  EditUserNoteParameters
>({
  displayName: "EditUserNoteForm",
  handleSubmit: (
    values: EditUserNoteParameters,
    formikBag: FormikBag<EditUserNoteFormProps, EditUserNoteParameters>
  ) => {
    formikBag.props
      .save(values)
      .catch(e => {
        /**/
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: (props: EditUserNoteFormProps) => {
    return {
      comment: "",
      state: props.userNote.state,
      priority: props.userNote.priority
    };
  },
  validationSchema: EditUserNoteValidationSchema
})(EditUserNoteFormTemplate);

export default AddUserNoteForm;
