import React from "react";
import Modal from "react-modal";
import withInput from "../../helpers/withInput";

import { WithInputProps } from "../../helpers/withInput.types";
import { ModalConfirmProp } from "./modal.types";
import "./ModalAlert.scss";

const ModalConfirm = (props: any & ModalConfirmProp) => {
  const {
    question,
    inputName,
    confirmText,
    loading,
    close,
    onConfirm
  }: {
    question: string | null;
    inputName: string | null;
    confirmText: string | null;
    loading: boolean;
    close: () => any;
    onConfirm: ((arg0?: string) => any) | null;
  } = props;
  const { input, setInput, onChange, onClick }: WithInputProps = props;

  return (
    <Modal
      isOpen={!!question}
      onRequestClose={close}
      className="Modal-small"
      contentLabel={question || ""}
    >
      <h2 className="title">{question}</h2>

      {!!inputName && (
        <div>
          <div className="label mt-2">{inputName}</div>
          <div className="value mb-2">
            <input value={input} onChange={onChange(setInput)} />
          </div>
        </div>
      )}

      <div className="actions buttons" style={{ alignItems: "center" }}>
        <button className="btn secondary filled" onClick={close}>
          No
        </button>
        {loading && (
          <img
            style={{ width: "47px", height: "30px", marginLeft: "30px" }}
            src="/media/loader.gif"
            alt="Loading..."
          />
        )}
        {!loading && onConfirm && (
          <button className="btn action filled" onClick={onClick(onConfirm)}>
            {confirmText}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default withInput(ModalConfirm);
