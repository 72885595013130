import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { LoginPage } from "./components/auth/Login";

import { UsersListPage } from "./components/users/List";
import User from "./components/users/User";
import { CampaignsPage } from "./components/campaigns/List";
import CampaignPage from "./components/campaigns/Campaign";

import { AuthState } from "shared/store/auth";
import LoadingIndicator from "../shared/components/loading/LoadingIndicator";

import { connect } from "react-redux";
import { ApplicationState } from "./store";
import { Permissions } from "./helpers/permissions";

import { ErrorPage } from "shared/components/error/ErrorPage";

interface PropsFromState {
  auth: AuthState;
}

class Router extends React.Component<PropsFromState> {
  public render() {
    const { auth } = this.props;

    const { isLoggedIn, isLoaded } = auth;

    if (!isLoaded) {
      return (
        <div className="app-body" title="Starting up..">
          <LoadingIndicator />
        </div>
      );
    }

    const permissions = new Permissions(auth);

    let defaultPage = "/login";
    if (isLoggedIn) {
      if (permissions.can("campaigns.list")) {
        defaultPage = "/campaigns";
      } else if (permissions.can("users.list")) {
        defaultPage = "/users";
      } else {
        return (
          <div className="app-body" title="Starting up..">
            You have no permissions set.
          </div>
        );
      }
    }

    return (
      <Switch>
        <ProtectedRoute
          isAllowed={!isLoggedIn}
          path="/login"
          redirectTo={defaultPage}
          component={LoginPage}
        />
        <ProtectedRoute
          isAllowed={permissions.can("users.view")}
          exact={true}
          path="/users/:username"
          redirectTo="/login"
          component={User}
        />
        <ProtectedRoute
          isAllowed={permissions.can("users.list")}
          exact={true}
          path="/users"
          redirectTo="/login"
          component={UsersListPage}
        />
        <ProtectedRoute
          isAllowed={permissions.can("campaigns.view")}
          exact={true}
          path="/campaigns/:campaignUID"
          redirectTo="/login"
          component={CampaignPage}
        />
        <ProtectedRoute
          isAllowed={permissions.can("campaigns.list")}
          exact={true}
          path="/campaigns"
          redirectTo="/login"
          component={CampaignsPage}
        />
        <Route path="/error" component={ErrorPage} />
        <Redirect to={defaultPage} />
      </Switch>
    );
  }
}

const ProtectedRoute = ({ isAllowed, redirectTo = "/", ...props }: any) =>
  isAllowed ? <Route {...props} /> : <Redirect to={redirectTo} />;

export default connect<PropsFromState, any, any, ApplicationState>(
  ({ auth }: ApplicationState) => ({
    auth
  })
)(Router);
