import React from "react";
import { ClientSubscriptionData } from "shared/store/user/types";

const SubscriptionSection = ({
  subscriptionData,
  labels
}: {
  subscriptionData: ClientSubscriptionData;
  labels: any;
}) => {
  if (!labels || !subscriptionData) {
    return (
      <div className="component-box">
        <div className="content" />
      </div>
    );
  }
  return (
    <React.Fragment>
      <h1 className="mt-0 mb-1">Subscription</h1>
      <h3
        dangerouslySetInnerHTML={{
          __html: labels.subscriptionStatusDetails
        }}
      />
      {subscriptionData.subscriptionValid && (
        <React.Fragment>
          {!subscriptionData.isCancelled && labels.renewalDetails && (
            <h3 className="value">{labels.renewalDetails}</h3>
          )}
          <div className="data-group">
            <label>Platform </label>
            <div className="value">{labels.platform}</div>
          </div>
          <div className="data-group">
            <label>Type</label>
            <div className="value">{labels.promotionDetails}</div>
          </div>
          {labels.codeDetails && (
            <div className="data-group">
              <label>Code</label>
              <div className="value">{labels.codeDetails}</div>
            </div>
          )}
          {labels.startTimeDate && (
            <div className="data-group">
              <label>Start date</label>
              <div className="value">{labels.startTimeDate}</div>
            </div>
          )}
          {labels.renewalTimeDate && labels.renewalLabel && (
            <div className="data-group">
              <label>{labels.renewalLabel} </label>
              <div className="value">{labels.renewalTimeDate}</div>
            </div>
          )}
          {labels.paymentDetails && (
            <>
              <div className="data-group">
                <label>Payment details</label>
                <div className="value">{labels.paymentDetails}</div>
              </div>
            </>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default SubscriptionSection;
