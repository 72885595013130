import logger from "./admin/helpers/logger";
import * as serviceWorker from "./serviceWorker";
import * as React from "react";

import { ApplicationState, rootReducer, rootSaga } from "admin/store";
import { authFetchAction } from "shared/store/auth";

import configureStore from "shared/configureStore";

import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "admin/App";
import { BrowserRouter } from "react-router-dom";

logger.init();

export const store = configureStore<ApplicationState>(
  {} as ApplicationState,
  rootReducer,
  rootSaga
);

// get user
store.dispatch(authFetchAction());

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root") as HTMLElement
);

// 2019-05-11 Marko Zabreznik
// We're not using service workers since the app is interlaced with another CMS
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
