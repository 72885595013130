import { AuthState } from "shared/store/auth";
import { RedeemType } from "../../shared/store/campaigns/types";

/**
 * Right now we only use Cognito for auth and we only get an array of Roles
 *  - Admin
 *  - CS
 *  - Marketeer
 */

export class Permissions {
  private permissions: string[];
  constructor(auth: AuthState) {
    this.permissions = [];

    if (!auth || !auth.user || !auth.isLoggedIn) {
      // no permissions by default
      return;
    }

    /* We convert the AuthState to an array of permissions that we would
     *  be expecting from the server in more ideal conditions
     */

    const isAdmin = auth.user.groups.indexOf("Admin") > -1;

    // the admin permission
    if (isAdmin) {
      this.permissions.push("admin");
    }

    // users
    if (isAdmin || auth.user.groups.indexOf("CS") > -1) {
      this.permissions = [
        ...this.permissions,
        "users.view",
        "users.list",
        "users.edit"
      ];
    }

    // campaigns
    if (isAdmin || auth.user.groups.indexOf("Marketeer") > -1) {
      this.permissions = [
        ...this.permissions,
        "campaigns.view",
        "campaigns.list",
        "campaigns.edit"
      ];
    }

    // only allow free access codes
    this.permissions = [
      ...this.permissions,
      `campaigns.edit.add-${RedeemType.FREE_ACCESS_CODE}`
    ];
    if (isAdmin) {
      // admins can add discounts too
      this.permissions = [
        ...this.permissions,
        `campaigns.edit.add-${RedeemType.PRODUCT_CODE}`,
        `campaigns.edit.add-${RedeemType.SCHOOL_CODE}`
      ];
    }

    // CS can also view campaigns
    if (auth.user.groups.indexOf("CS") > -1) {
      this.permissions = [
        ...this.permissions,
        "campaigns.view",
        "campaigns.list"
      ];
    }
  }

  public can(permission: string) {
    return this.permissions.includes(permission);
  }
}
