import React from "react";
import LoadingIndicator from "../../loading/LoadingIndicator";

const FormButton = ({ text, loading }: { text: string; loading: string }) => {
  return (
    <div className={`form-group with-loading-block ${loading}`}>
      <div className="loading-block mt-2">
        <LoadingIndicator />
      </div>
      <button className="btn filled action" type="submit">
        {text}
      </button>
    </div>
  );
};

export default FormButton;
