import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  ClientDynamoUser,
  ProfileUpdateParameters,
} from "shared/store/user/types";

import UsersApi from "admin/store/users/api";
import { captureException } from "../../../helpers/logger";
import EditForm from "shared/components/forms/users/SettingsForm";
import EditFormAdmin from "./SettingsForm";

const Settings = ({ userData }: { userData: ClientDynamoUser }) => {
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const updateUserData = async (updateParameters: ProfileUpdateParameters) => {
    try {
      if (userData && userData.username) {
        await UsersApi.putUser(userData.username, {
          ...userData,
          ...updateParameters,
        });
        toast.success("User details update");
      }
    } catch (e) {
      toast.error(e.toString());
    }
  };

  const resetUserPassword = async () => {
    try {
      if (userData) {
        await UsersApi.resetPassword(userData.username);
        toast.success("Password reset request sent");
      } else {
        toast.error("No user");
      }
    } catch (e) {
      captureException(e, { category: "admin.user.resetPassword" });
      toast.error(e.message);
    }
  };

  return (
    <div className="component-box content">
      <EditForm
        editMode={editMode}
        switchMode={toggleEditMode}
        hasSchoolSubscription={false}
        userData={userData}
        userDataUpdate={updateUserData}
      />
      <button className="btn admin filled wide" onClick={resetUserPassword}>
        Reset password
      </button>
      <EditFormAdmin
        editMode={editMode}
        userData={userData}
        userDataUpdate={updateUserData}
      />
    </div>
  );
};

export default Settings;
