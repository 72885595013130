import React from "react";
import Modal from "react-modal";

import { DateFormat, timeToFormat } from "shared/helpers/date";
import { formatCurrency } from "shared/helpers/currency";
import { ClientAdminData, SubscriptionHistory } from "admin/store/users";

import DataTable from "shared/components/table/DataTable";

const SubscriptionHistoryModal = ({
  isOpen,
  onClose,
  adminData,
}: {
  isOpen: boolean;
  onClose: () => void;
  adminData: ClientAdminData;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="Modal-big"
      contentLabel="History"
    >
      <div className="responsive-table list-table subscription-history">
        <h2>
          Subscription History{" "}
          <div onClick={onClose} className="close">
            X
          </div>
        </h2>
        <div className="wrapper">
          <DataTable
            data={adminData.subscriptionHistory}
            columns={subscriptionHistoryColumns}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionHistoryModal;

const subscriptionHistoryColumns = [
  {
    header: "Event",
    accessor: "state",
  },
  {
    header: "Provider",
    accessor: "platform",
  },
  {
    header: "Type",
    accessor: "subscriptionType",
  },
  {
    header: "Payment State",
    accessor: "paymentState",
  },
  {
    header: "Valid",
    accessor: (s: SubscriptionHistory) => (s.subscriptionValid ? "valid" : "/"),
    id: "subscriptionValid",
  },
  {
    header: "Price",
    accessor: (s: SubscriptionHistory) =>
      s.subscriptionPrice
        ? formatCurrency(
            s.subscriptionPrice || 0.0,
            s.subscriptionCurrency || ""
          )
        : "/",
  },
  {
    header: "Start date",
    accessor: (s: SubscriptionHistory) =>
      timeToFormat(s.startTimeMillis, DateFormat.ShortDate),
    id: "startTimeMillis",
  },
  {
    header: "Renewal date",
    accessor: (s: SubscriptionHistory) =>
      timeToFormat(s.renewalTimeMillis, DateFormat.ShortDate),
    id: "renewalTimeMillis",
  },
  {
    header: "Trial",
    accessor: (s: SubscriptionHistory) => (s.trialActive ? "active" : "/"),
    id: "trialActive",
  },
  {
    header: "End Time",
    accessor: (s: SubscriptionHistory) => {
      if (s.lastTrialEndTime) {
        return timeToFormat(s.lastTrialEndTime, DateFormat.ShortDate);
      } else {
        return "/";
      }
    },
    id: "lastTrialEndTime",
  },
  {
    header: "Subscription Id",
    accessor: (s: SubscriptionHistory) => s.subscriptionId,
    id: "subscriptionId",
  },
  {
    header: "Purchase Id",
    accessor: (s: SubscriptionHistory) => s.purchaseId,
    id: "purchaseId",
  },
  {
    header: "Cancelled",
    accessor: (s: SubscriptionHistory) => (s.isCancelled ? "cancelled" : "/"),
    id: "isCancelled",
  },
  {
    header: "Refunded",
    accessor: (s: SubscriptionHistory) => (s.isRefunded ? "refunded" : "/"),
    id: "isRefunded",
  },
  {
    header: "Grace",
    accessor: (s: SubscriptionHistory) => (s.isGrace ? "grace" : "/"),
    id: "isGrace",
  },
];
