import React, { useState } from "react";
import {
  UserNote,
  UserNotes,
  UserNotePriority,
  UserNotePriorityMap
} from "admin/store/users";

import { deleteUserNote } from "admin/store/users/api";

import { toast } from "react-toastify";

import EditUserNoteModal from "./EditUserNote";
import ModalConfirm from "shared/components/modal/ModalConfirm";

import "./UserNote.scss";

const Note = ({
  userNote,
  username,
  noteId,
  onUpdate
}: {
  userNote: UserNote;
  username: string;
  noteId: string;
  onUpdate: (notes: UserNotes) => void;
}) => {
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const closeEditNoteModal = () => {
    setShowEditNoteModal(false);
  };
  const updateAndCloseModal = (notes: UserNotes) => {
    onUpdate(notes);
    closeEditNoteModal();
  };
  const openEditNoteModal = () => {
    setShowEditNoteModal(true);
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const onDeleteCancelled = () => {
    setShowDeleteModal(false);
  };
  const onDeleteConfirmed = async () => {
    setShowDeleteModal(false);
    const updatedNotes = await deleteUserNote(username, noteId);
    toast.success("Deleted note");
    onUpdate(updatedNotes);
  };

  const dateString = (addedAt: number): string => {
    return new Date(addedAt).toLocaleString();
  };
  const priorityClassName = (priority: UserNotePriority) => {
    if (priority === UserNotePriority.HIGH) {
      return "important";
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <h3 className={priorityClassName(userNote.priority)}>
          {`Priority: ${UserNotePriorityMap[userNote.priority]}`}{" "}
        </h3>
        <div className="header">
          <h3 className="title">{userNote.title}</h3>
        </div>
        <div className="comments">
          {userNote.comments.map(comment => (
            <React.Fragment key={comment.addedAt}>
              <div className="date-added">{dateString(comment.addedAt)}</div>
              <div className="comment">{comment.comment}</div>
            </React.Fragment>
          ))}
        </div>
        <div className="actions">
          <button
            className="btn action filled wide"
            onClick={openEditNoteModal}
          >
            Update
          </button>{" "}
          <button className="btn secondary filled" onClick={openDeleteModal}>
            Delete
          </button>
        </div>
      </div>
      <EditUserNoteModal
        isOpen={showEditNoteModal}
        onClose={closeEditNoteModal}
        onUpdate={updateAndCloseModal}
        userNote={userNote}
        username={username}
        noteId={noteId}
      />
      {showDeleteModal && (
        <ModalConfirm
          question={`Are you sure you want to delete this note: ${userNote.title}`}
          confirmText="Yes"
          loading={false}
          close={onDeleteCancelled}
          onConfirm={onDeleteConfirmed}
        />
      )}
    </React.Fragment>
  );
};

export default Note;
