import React from "react";
import Modal from "react-modal";

import { updateUserNote } from "admin/store/users/api";
import {
  UserNote,
  EditUserNoteParameters,
  UserNotes
} from "admin/store/users/types";

import { toast } from "react-toastify";

import EditUserNoteForm from "./forms/EditUserNoteForm";

const EditUserNoteModal = ({
  isOpen,
  onClose,
  onUpdate,
  userNote,
  username,
  noteId
}: {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (notes: UserNotes) => void;
  userNote: UserNote;
  username: string;
  noteId: string;
}) => {
  const save = async (parameters: EditUserNoteParameters) => {
    try {
      const updatedNotes = await updateUserNote(username, noteId, parameters);
      toast.success("Updated note");
      onUpdate(updatedNotes);
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="Modal-big"
      contentLabel="Edit User Note"
    >
      <h2>
        Edit note
        <div onClick={onClose} className="close">
          X
        </div>
      </h2>
      <EditUserNoteForm save={save} userNote={userNote} />
    </Modal>
  );
};

export default EditUserNoteModal;
