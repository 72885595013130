import axios, { AxiosResponse } from "axios";
import { captureBreadcrumb, captureException, reportDialog } from "./logger";
import { ReportDialogOptions } from "@sentry/browser";
import { ApiError, ServerError, ValidationError } from "shared/helpers/errors";

axios.defaults.baseURL =
  process.env.REACT_APP_API_ENDPOINT ||
  process.env.REACT_APP_ADMIN_API_ENDPOINT;

async function handleSuccess(a: AxiosResponse) {
  return a.data;
}

async function handleError(e: any) {
  if (e.response) {
    if (e.response.data) {
      const data = e.response.data;

      captureBreadcrumb({ data, message: data.message, category: "response" });

      if (data.reportId) {
        // tslint:disable-next-line:no-console
        console.info(
          "&#1F997; Looks like you've caught a bug: " + data.reportId
        );

        // report dialog for server error!
        const reportData: ReportDialogOptions = {
          eventId: data.reportId,
          dsn:
            process.env.REACT_APP_API_SENTRY_DSN ||
            process.env.REACT_APP_SENTRY_DSN
        };
        reportDialog(reportData);
        throw new ServerError(data);
      }

      // legacy admin errors
      if (data.details) {
        throw new ValidationError({
          message: "Some fields have errors",
          code: data.name,
          errors: data.details.map((d: any) => {
            return {
              message: d.message,
              attribute: d.path.join("."),
              code: d.type
            };
          })
        });
      }

      switch (data.code) {
        case "validation-error":
          throw new ValidationError(data);
        default:
          throw new ApiError(data);
      }
    }
  }
  captureException(e, {
    message: "Unhandled API Exception",
    category: "response",
    extra: e.response
  });
  throw e;
}

export async function get(path: string, data: any = {}, headers: any = {}) {
  return await axios
    .get(path, {
      params: data,
      headers
    })
    .then(handleSuccess)
    .catch(handleError);
}

export async function post(path: string, data: any = {}, headers: any = {}) {
  return await axios
    .post(path, data, {
      headers
    })
    .then(handleSuccess)
    .catch(handleError);
}

export async function put(path: string, data: any = {}, headers: any = {}) {
  return await axios
    .put(path, data, {
      headers
    })
    .then(handleSuccess)
    .catch(handleError);
}

export async function del(path: string, headers: any = {}) {
  return await axios
    .delete(path, {
      headers
    })
    .then(handleSuccess)
    .catch(handleError);
}

export default {
  post,
  get,
  put,
  del
};
