import { Campaign } from "admin/store/campaigns";

const convertToCsv = (campaign: Campaign) => {
  let str = "PromoCode,CampaignName,Start,End,Duration,Used\r\n";
  for (const p of campaign.uniqueCodes) {
    str += `${p.promoCode},${campaign.campaignName},${campaign.startOfCampaign},${campaign.endOfCampaign},${campaign.promoDurationInDays},${p.countOfRedeemed}\r\n`;
  }
  return str;
};

export const exportCsv = (campaign: Campaign) => {
  if (!campaign) {
    return;
  }

  const csv = convertToCsv(campaign);
  const exportedFilename = campaign.campaignName + ".csv" || "export.csv";

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
