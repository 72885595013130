import { Reducer } from "redux";
import { CampaignActions } from "./actions";
import { CampaignActionsTypes, CampaignsState } from "./types";

const initialState: CampaignsState = {
  campaigns: [],
  status: null,
  isLoading: false
};

const reducer: Reducer<CampaignsState, CampaignActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CampaignActionsTypes.CAMPAIGNS_SET: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as campaignsReducer };
