import React, { useState, useEffect } from "react";
import UsersApi from "../../../store/users/api";
import { captureException } from "admin/helpers/logger";

import { FullUserState } from "admin/store/users";
import {
  ClientSubscriptionData,
  ClientDynamoUser,
} from "shared/store/user/types";

import ModalConfirm from "shared/components/modal/ModalConfirm";
import { toast } from "react-toastify";

import "./DangerousSection.scss";

const DangerousSection = ({ user }: { user: FullUserState }) => {
  const [userData, setUserData] = useState<ClientDynamoUser>();
  const [
    subscriptionData,
    setSubscriptionData,
  ] = useState<ClientSubscriptionData>();
  useEffect(() => {
    if (user) {
      if (user.UserData) {
        setUserData(user.UserData);
      }
      if (user.SubscriptionData) {
        setSubscriptionData(user.SubscriptionData);
      }
    }
  }, [user]);

  const [modalQuestion, setModalQuestion] = useState<string | null>(null);
  const [modalInput, setModalInput] = useState<string | null>(null);
  const [onModalConfirm, setOnModalConfirm] = useState<
    ((InputValue?: string) => any) | null
  >();
  const [isLoading, setIsLoading] = useState(false);
  const closeConfirmModal = () => {
    setModalQuestion(null);
    setModalInput(null);
  };

  const confirmDeleteUser = () => {
    setModalQuestion("Are you sure you want to delete this user?");
    setOnModalConfirm(() => deleteUser);
  };

  const deleteUser = async () => {
    if (userData && userData.username) {
      try {
        setIsLoading(true);
        await UsersApi.delUser(userData.username);
        toast.success("User deleted");
      } catch (e) {
        captureException(e, { category: "admin.user.delete" });
        toast.error(e.message);
      } finally {
        setIsLoading(false);
        closeConfirmModal();
      }
    }
    closeConfirmModal();
  };

  const confirmDeleteSubscription = () => {
    setModalQuestion(
      "Are you sure you want to delete this user's subscription?"
    );
    setOnModalConfirm(() => deleteSubscription);
  };

  const deleteSubscription = async () => {
    if (userData && userData.username) {
      try {
        setIsLoading(true);
        await UsersApi.delSub(userData.username);
        toast.success("Subscription removed");
      } catch (e) {
        captureException(e, { category: "admin.sub.delete" });
        toast.error(e.message);
      } finally {
        setIsLoading(false);
        closeConfirmModal();
      }
    }
    closeConfirmModal();
  };

  const confirmTransferSubscription = () => {
    setModalQuestion(
      "Are you sure you want to transfer this user's subscription?"
    );
    setModalInput("Destination user UID");
    setOnModalConfirm(() => transferSubscription);
  };

  const transferSubscription = async (inputValue?: string) => {
    if (inputValue && userData && userData.username) {
      const destinationUsername = inputValue.replace("@", "-at-");
      const regexUID = new RegExp(`^[\\w-.+]+-at-([\\w-]+\\.)+[\\w-]{2,4}$`);
      if (regexUID.test(destinationUsername)) {
        setIsLoading(true);
        try {
          await UsersApi.transferSub(userData.username, destinationUsername);
          toast.success("Subscription transferred to " + destinationUsername);
        } catch (e) {
          captureException(e, {
            category: "admin.user-transfer-subscription",
            extra: { source: userData.username, dest: destinationUsername },
          });
          toast.error(e.message);
        }
      }
    }
    setIsLoading(false);
    closeConfirmModal();
  };

  return (
    <React.Fragment>
      <div className="component-box danger ">
        <div className="section border">
          <div className="content mb-2">
            <h1 className="mt-0 mb-1">DANGER ZONE</h1>
            <div className="actions mt-3">
              <button
                className="btn admin filled wide"
                onClick={confirmDeleteUser}
              >
                Delete User
              </button>
              {subscriptionData && (
                <button
                  className="btn admin filled wide"
                  onClick={confirmDeleteSubscription}
                >
                  Remove Subscription
                </button>
              )}
              {subscriptionData && subscriptionData.subscriptionValid && (
                <button
                  className="btn admin filled wide"
                  onClick={confirmTransferSubscription}
                >
                  Transfer Subscription
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        question={modalQuestion}
        confirmText="Yes"
        inputName={modalInput}
        loading={isLoading}
        close={closeConfirmModal}
        onConfirm={onModalConfirm}
      />
    </React.Fragment>
  );
};

export default DangerousSection;
