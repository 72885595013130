import * as React from "react";
import { connect } from "react-redux";
import { RouterProps } from "react-router";
import { Dispatch } from "redux";

import { AuthState } from "shared/store/auth";
import LoadingIndicator from "../../../shared/components/loading/LoadingIndicator";

import {
  Campaign,
  CampaignsState,
  PromoCodeTypeLabels,
} from "../../store/campaigns";
import { ApplicationState } from "../../store";
import * as campaignActions from "../../store/campaigns/actions";
import { DateFormat, timeToFormat } from "shared/helpers/date";
import { NavLink } from "react-router-dom";

import "./List.scss";

interface PropsFromState {
  campaigns: CampaignsState;
  auth: AuthState;
}

interface PropsFromDispatch {
  filterCampaigns: typeof campaignActions.campaignsFilterAction;
}

class Page extends React.Component<
  PropsFromState & PropsFromDispatch & RouterProps
> {
  private static columns = [
    {
      Header: "Campaign",
      accessor: "campaignName",
    },
    {
      Header: "Start",
      accessor: (c: Campaign) =>
        c.startOfCampaign
          ? timeToFormat(c.startOfCampaign, DateFormat.ShortDate)
          : "/",
      id: "startOfCampaign",
    },
    {
      Header: "Type",
      accessor: (c: Campaign) => PromoCodeTypeLabels[c.type],
      id: "type",
    },
    {
      Header: "Duration",
      accessor: "promoDurationInDays",
    },
    {
      Header: "Amount",
      accessor: "numberOfCodes",
    },
    {
      Header: "Redeemed",
      accessor: "countOfRedeemed",
    },
  ];

  public componentDidMount() {
    this.props.filterCampaigns("");
  }

  public render() {
    const columns = Page.columns;
    const { history } = this.props;
    const { isLoading, campaigns } = this.props.campaigns;

    const campaignClick = (
      event: React.SyntheticEvent<HTMLTableRowElement>
    ) => {
      const id = event.currentTarget.dataset.uid;
      if (id) {
        history.push(`/campaigns/${id}`);
      }
    };

    return (
      <div className="app-body wide campaigns-page">
        <div className="components-wrap">
          <h1>
            Campaigns
            <div className="actions">
              <NavLink className="btn purple" to="/campaigns/create">
                Create new
              </NavLink>
            </div>
          </h1>

          {isLoading && (
            <div className="app-body" title="Loading campaigns..">
              <LoadingIndicator />
            </div>
          )}

          {!isLoading && (!campaigns || campaigns.length) < 1 && (
            <div className="text-center mt-2 large">No campaigns found</div>
          )}

          {!isLoading && campaigns && campaigns.length > 0 && (
            <div className="list-table mt-2">
              <table>
                <thead>
                  <tr>
                    {columns.map((x, i) => {
                      return <th key={i}>{x.Header}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {campaigns.map((u, i) => {
                    return (
                      <tr
                        key={i}
                        onClick={campaignClick}
                        data-uid={u.campaignUID}
                      >
                        {columns.map((h, j) => {
                          if (typeof h.accessor === "string") {
                            return (
                              <td key={i + "-" + j}>
                                <span className="cell-header">{h.Header}:</span>
                                {
                                  // @ts-ignore
                                  u[h.accessor]
                                }
                              </td>
                            );
                          } else if (typeof h.accessor === "function") {
                            return (
                              <td key={i + "-" + j}>
                                <span className="cell-header">{h.Header}:</span>
                                {h.accessor(u)}
                              </td>
                            );
                          } else {
                            return <td />;
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const CampaignsPage = connect<
  PropsFromState,
  PropsFromDispatch,
  any,
  ApplicationState
>(
  ({ campaigns, auth }: ApplicationState) => ({
    auth,
    campaigns,
  }),
  (dispatch: Dispatch) => ({
    filterCampaigns: (filter: string) =>
      dispatch(campaignActions.campaignsFilterAction(filter)),
  })
)(Page);
