import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import * as React from "react";

import {
  ClientDynamoUser,
  ProfileUpdateParameters,
  putCurrentUserPrivacySchema
} from "../../store/user/types";
import LoadingIndicator from "../loading/LoadingIndicator";

interface State {
  editMode: boolean;
}

interface PrivacyAndNotificationsProps {
  userData: ClientDynamoUser;
  userDataUpdate: (u: ProfileUpdateParameters) => Promise<void>;
}

interface EditFormProps {
  editMode: boolean;
  switchMode: () => void;
  userDataUpdate: (u: ProfileUpdateParameters) => Promise<void>;
  userData: ClientDynamoUser;
}

const EditFormTemplate = (
  props: FormikProps<ProfileUpdateParameters> & EditFormProps
) => {
  const { values, switchMode, isSubmitting, editMode } = props;
  const cancel = () => {
    props.resetForm();
    switchMode();
  };

  const { country, optOutOfSale } = props.userData;
  const hidden = editMode ? "" : "hidden";
  const toggleAnalyticsOptIn = () => {
    if (editMode) {
      values.analyticsOptIn = !values.analyticsOptIn;
    }
  };
  const toggleTwilightUpdateOptIn = () => {
    if (editMode) {
      values.twilightUpdateOptIn = !values.twilightUpdateOptIn;
    }
  };
  const togglePartnerUpdateOptIn = () => {
    if (editMode) {
      values.partnerUpdateOptIn = !values.partnerUpdateOptIn;
    }
  };
  const toggleOptOutOfSale = () => {
    if (editMode) {
      values.optOutOfSale = !values.optOutOfSale;
    }
  };

  return (
    <Form className="content wide">
      <h1 className="text-center">Privacy settings</h1>
      <div className="form-group">
        <label className="checkbox" htmlFor="analyticsOptIn">
          <span onClick={toggleAnalyticsOptIn}>
            <Field
              id="analyticsOptIn"
              name="analyticsOptIn"
              component="input"
              type="checkbox"
              checked={values.analyticsOptIn}
              disabled={!editMode}
            />
            <span />
          </span>
          Analytics settings
          <div className={`fine-print ${hidden}`}>
            We use analytics to improve the performance and quality of our app.
            We do not share analytics data with any 3rd party and we do not use
            analytics to serve advertisements in the app.
          </div>
        </label>
      </div>
      <div className="form-group">
        <label className="checkbox" htmlFor="twilightUpdateOptIn">
          <span onClick={toggleTwilightUpdateOptIn}>
            <Field
              id="twilightUpdateOptIn"
              name="twilightUpdateOptIn"
              component="input"
              type="checkbox"
              checked={values.twilightUpdateOptIn}
              disabled={!editMode}
            />
            <span />
          </span>
          Moshi updates
          <div className={`fine-print ${hidden}`}>
            Sign up to receive emails and updates from us about new app features
            and offers
          </div>
        </label>
      </div>
      <div className="form-group">
        <label className="checkbox" htmlFor="partnerUpdateOptIn">
          <span onClick={togglePartnerUpdateOptIn}>
            <Field
              id="partnerUpdateOptIn"
              name="partnerUpdateOptIn"
              component="input"
              type="checkbox"
              checked={values.partnerUpdateOptIn}
              disabled={!editMode}
            />
            <span />
          </span>
          Partners updates
          <div className={`fine-print ${hidden}`}>
            Sign up to receive emails and offers from our friends and partners
            that we think you will love
          </div>
        </label>
      </div>
      {(country === "US" || optOutOfSale !== undefined) && (
        <div className="form-group">
          <label className="checkbox" htmlFor="optOutOfSale">
            <span onClick={toggleOptOutOfSale}>
              <Field
                id="optOutOfSale"
                name="optOutOfSale"
                component="input"
                type="checkbox"
                checked={values.optOutOfSale}
                disabled={!editMode}
              />
              <span />
            </span>
            Opt out of sale
            <div className={`fine-print ${hidden}`}>
              The California Consumer Privacy Act (CCPA) is a data privacy law
              that establishes various rights for California state residents. If
              applicable you can opt out of us disclosing certain information
              about you to companies like our advertising-related partners as
              further described in our Privacy Policy. The information shared
              with these businesses may help provide you with a more
              personalized content and advertising experience.
            </div>
          </label>
        </div>
      )}

      {!editMode && (
        <div className={`form-group with-loading-block`}>
          <div className="actions mt-3">
            <button
              className="btn secondary filled"
              type="button"
              onClick={switchMode}
            >
              Edit
            </button>
          </div>
        </div>
      )}
      {editMode && (
        <div className={`form-group with-loading-block mt-2`}>
          {(isSubmitting && <LoadingIndicator />) || (
            <div className="actions">
              <button className="btn action filled" type="submit">
                Save
              </button>
              <button className="btn secondary" type="button" onClick={cancel}>
                Cancel
              </button>
            </div>
          )}
        </div>
      )}
    </Form>
  );
};

const EditForm = withFormik({
  displayName: "PrivacyForm",
  handleSubmit: (
    values: ProfileUpdateParameters,
    formikBag: FormikBag<EditFormProps, ProfileUpdateParameters>
  ) => {
    formikBag.props
      .userDataUpdate(values)
      .then(() => {
        formikBag.setSubmitting(false);
        formikBag.props.switchMode();
      })
      .catch(() => {
        // nothing much to do here, toast was handled by parent
        //  and there aren't any fields errors possible
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: (props: EditFormProps) => {
    return {
      partnerUpdateOptIn: props.userData.partnerUpdateOptIn,
      twilightUpdateOptIn: props.userData.twilightUpdateOptIn,
      analyticsOptIn: props.userData.analyticsOptIn || false,
      optOutOfSale: props.userData.optOutOfSale
    } as ProfileUpdateParameters;
  },
  validationSchema: putCurrentUserPrivacySchema
})(EditFormTemplate);

class PrivacyAndNotificationsSection extends React.PureComponent<
  PrivacyAndNotificationsProps,
  State
> {
  constructor(props: PrivacyAndNotificationsProps) {
    super(props);
    this.state = {
      editMode: false
    };
  }

  public render() {
    const switchMode = this.switchMode.bind(this);
    const { userData, userDataUpdate } = this.props;
    return (
      <div
        className={
          this.state.editMode ? "component-box editing" : "component-box"
        }
      >
        <EditForm
          editMode={this.state.editMode}
          switchMode={switchMode}
          userData={userData}
          userDataUpdate={userDataUpdate}
        />
      </div>
    );
  }

  private switchMode() {
    this.setState({ editMode: !this.state.editMode });
  }
}
export default PrivacyAndNotificationsSection;
