export enum RedeemType {
  FREE_ACCESS_CODE = "free-access-code",
  PRODUCT_CODE = "product-code",
  REFERRAL_CODE = "referral-code",
  SCHOOL_CODE = "school-code"
}

export enum PromotionSlug {
  REDEEM_CODE = "redeem",
  PLUSH_CODE = "plush"
}
